import * as React from "react";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

function ColorCard({ title, total, thisMonth, className, ...props }) {
  return (
    <Card className={`${className} w-[15rem] h-[10rem] hover:mt-[-20px] transition-all hover:shadow-lg cursor-pointer`} {...props}>
      <CardHeader classname="py-2">
        <CardTitle>
          <span className="w-full space-y-5">
            <span className="flex gap-3 font-normal justify-between text-[18px]">
              <p className="font-medium">{title}</p>
              <p className="opacity-80">This Month</p>
            </span>
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent className="py-2">
        <section className="w-full flex justify-between text-[35px]">
          <p className={"font-bold"}>{total}</p>
          <p className="opacity-80 font-bold">{thisMonth}</p>
        </section>
      </CardContent>
    </Card>
  );
}

export default ColorCard;
