import Heading from "@/components/Heading";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SERVER_LINK } from "@/constant";
import AlertBox from "@/components/AlertBox";
import Loading from "@/components/Loading";
import { deleteUpdateData } from "@/store/updateSlice";

function AddStudents() {
  const [loading, setLoading] = useState();
  const { register, handleSubmit } = useForm();
  const institutionId = useSelector(
    (state) => state.auth?.authInfo?.institutionId
  );
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch()
  //dispatch(deleteUpdateData())
  const updateData = useSelector(state => state.updateData?.updateData)

  //console.log(updateData);

  async function createStudent(data) {
    data = {
      ...data,
      father: {
        name: data.father,
        occupation: data.fatherProfession,
        income: data.fatherIncome,
        education: data.fatherEducation,
        mobileNumber: data.fatherMobileNumber,
      },
      mother: {
        name: data.mother,
        occupation: data.motherProfession,
        income: data.motherIncome,
        education: data.motherEducation,
        mobileNumber: data.motherMobileNumber,
      },
    };

    const deleteFields = [
      "fatherProfession",
      "fatherIncome",
      "fatherEducation",
      "fatherMobileNumber",
      "motherProfession",
      "motherIncome",
      "motherEducation",
      "motherMobileNumber",
    ];

    deleteFields.map((e) => {
      delete data[e];
    });

    //it is for update

    if(data.admissionDate.length === 0 || data.dateOfBirth.length === 0) {
      if(updateData?.admissionDate.length > 0 && updateData?.dateOfBirth.length > 0) {
        data.admissionDate = updateData?.admissionDate 
        data.dateOfBirth = updateData?.dateOfBirth 
      }
      else {
        setOpen(true)
        setSuccess(false)
        setErrorMessage("Please select Academic Session and Date Of Birth")
      }
    } 

    //console.log(data);

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/student/create`,
        method: "post",
        data: data,
      });
      console.log(res);
      if (res.status >= 200 || res.status < 400) {
        console.log("yes");
        setOpen(true);
        setSuccess(true);
        setErrorMessage("Update Successfull");
      }
    } catch (error) {
        console.log(error);
      setOpen(true);
      setSuccess(false);
      setErrorMessage(error?.response?.data?.error?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full px-10 py-5">
      {success && (
        <AlertBox open={open} setOpen={setOpen} success={success} title={errorMessage} />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <Heading>Add New Student</Heading>
      <div className="w-full flex justify-center items-center gap-5 mt-10">
        <form
          className="w-full flex justify-center items-center flex-wrap gap-5"
          onSubmit={handleSubmit(createStudent)}
        >
          <div className="w-full flex justify-center items-center gap-5 flex-wrap">
            <InputField
              label="Name"
              placeholder=""
              type="text"
              defaultValue={updateData?.name}
              {...register("name")}
            />

            <InputField
              label="Registration Number"
              placeholder=""
              type="text"
              defaultValue={updateData?.registrationNumber}
              {...register("registrationNumber")}
            />

            <InputField
              label="Institution Id"
              placeholder=""
              type="text"
              value={institutionId}
              {...register("institutionId")}
            />

            {/* <InputField
              label="Picture"
              placeholder=""
              type="file"
              {...register("picture")}
            /> */}

            <InputField
              label="Admission Date"
              placeholder=""
              type="date"
              defaultValue={updateData?.date}
              {...register("admissionDate")}
            />

            <InputField
              label="Class"
              placeholder=""
              type="text"
              defaultValue={updateData?.class_}
              {...register("class_")}
            />

            <InputField
              label="Academic Session"
              placeholder="E.g, 2024-25"
              type="text"
              defaultValue={updateData?.academicSession}
              {...register("academicSession")}
            />

            <InputField
              label="Discount In Fee in %"
              placeholder="E.g, 5"
              type="text"
              defaultValue={updateData?.discountInFee}
              {...register("discountInFee")}
            />

            <InputField
              label="Mobile Number"
              placeholder="E.g, +911234567890"
              type="text"
              defaultValue={updateData?.mobileNumber}
              {...register("mobileNumber")}
            />

            <InputField
              label="Date Of Birth"
              placeholder=""
              type="date"
              defaultValue={updateData?.dateOfBirth}
              {...register("dateOfBirth")}
            />

            <InputField
              label="Gender"
              placeholder=""
              type="text"
              defaultValue={updateData?.gender}
              {...register("gender")}
            />

            <InputField
              label="Identification Mark"
              placeholder=""
              type="text"
              defaultValue={updateData?.identificationMark}
              {...register("identificationMark")}
            />

            <section className="flex flex-col gap-2">
              <Label>Blood Group</Label>
              <select
                {...register("bloodGroup")}
                defaultValue={updateData?.bloodGroup}
                className="w-[24rem] px-3 py-2 text-slate-700 rounded-md border-[1px] bg-white"
              >
                <option value="A+">A+</option>
                <option value="A+">A-</option>
                <option value="A+">B+</option>
                <option value="A+">O+</option>
                <option value="A+">O-</option>
              </select>
            </section>

            <InputField
              label="Diseases If Any"
              placeholder=""
              type="text"
              defaultValue={updateData?.diseases}
              {...register("diseases")}
            />

            <InputField
              label="Caste"
              placeholder="E.g, General"
              type="text"
              defaultValue={updateData?.caste}
              {...register("caste")}
            />

            <InputField
              label="Previous School"
              placeholder=""
              type="text"
              defaultValue={updateData?.previousSchool}
              {...register("previousSchool")}
            />

            <InputField
              label="Previous Roll Number"
              placeholder=""
              type="text"
              defaultValue={updateData?.previousRollNumber}
              {...register("previousRollNumber")}
            />

            <InputField
              label="Additional Note"
              placeholder=""
              type="text"
              defaultValue={updateData?.additionalNote}
              {...register("additionalNote")}
            />

            <InputField
              label="Orphan Student"
              placeholder="If not, NIL"
              type="text"
              defaultValue={updateData?.orphanStudent}
              {...register("orphanStudent")}
            />

            <InputField
              label="Total Siblings"
              placeholder=""
              type="text"
              defaultValue={updateData?.totalSiblings}
              {...register("totalSiblings")}
            />

            <InputField
              label="Address"
              placeholder=""
              type="text"
              defaultValue={updateData?.address}
              {...register("address")}
            />

            <InputField
              label="Religion"
              placeholder="E.g, Hindu"
              type="text"
              defaultValue={updateData?.religion}
              {...register("religion")}
            />

            <InputField
              label="Father Name"
              placeholder=""
              type="text"
              defaultValue={updateData?.father?.name}
              {...register("father")}
            />

            <InputField
              label="Father's Education"
              placeholder=""
              type="text"
              defaultValue={updateData?.father?.education}
              {...register("fatherEducation")}
            />

            <InputField
              label="Father's Mobile Number"
              placeholder=""
              type="text"
              defaultValue={updateData?.father?.mobileNumber}
              {...register("fatherMobileNumber")}
            />

            <InputField
              label="Father's Profession"
              placeholder=""
              type="text"
              defaultValue={updateData?.father?.occupation}
              {...register("fatherProfession")}
            />

            <InputField
              label="Father's Income"
              placeholder=""
              type="text"
              defaultValue={updateData?.father?.income}
              {...register("fatherIncome")}
            />

            <InputField
              label="Mother Name"
              placeholder=""
              type="text"
              defaultValue={updateData?.mother?.name}
              {...register("mother")}
            />

            <InputField
              label="Mother's Education"
              placeholder=""
              type="text"
              defaultValue={updateData?.mother?.education}
              {...register("motherEducation")}
            />

            <InputField
              label="Mother's Mobile Number"
              placeholder=""
              type="text"
              defaultValue={updateData?.mother?.mobileNumber}
              {...register("motherMobileNumber")}
            />

            <InputField
              label="Mother's Profession"
              placeholder=""
              type="text"
              defaultValue={updateData?.mother?.occupation}
              {...register("motherProfession")}
            />

            <InputField
              label="Mother's Income"
              placeholder=""
              type="text"
              defaultValue={updateData?.mother?.income}
              {...register("motherIncome")}
            />
          </div>
          <Button type="submit">
            {loading ? <Loading color={"bg-white"} /> : "Create"}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default AddStudents;
