import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Landing from "./pages/Landing";
import Dashboard from "./pages/Dashboard";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "./store/authSlice.js";
import InstituteProfle from "./pages/InstituteProfle";
import RegisterInstitution from "./pages/RegisterInstitution";
import AccountSettings from "./pages/AccountSettings";
import PhoneNumberVerify from "./pages/PhoneNumberVerify";
import OtpVerify from "./pages/OtpVerify";
import { SERVER_LINK } from "./constant";
import InstituteBankDetails from "./pages/InstituteBankDetails";
import AllEmployees from "./pages/AllEmployees";
import AddEmployee from "./pages/AddEmployee";
import Classes from "./pages/Classes";
import AllStudents from "./pages/AllStudents";
import AddStudents from "./pages/AddStudents";
import PromoteStudents from "./pages/PromoteStudents";
import ChartOfAccount from "./pages/ChartOfAccount";
import AccountStatement from "./pages/AccountStatement";

function App() {
  const authStatus = useSelector((state) => state.auth.status);
  const authInfo = useSelector((state) => state.auth.authInfo);
  //getting data from localstorage
  const local_accessToken = localStorage.getItem(
    "vidyalay_userData_accessToken"
  );
  const local_refreshToken = localStorage.getItem(
    "vidyalay_userData_refreshToken"
  );
  const local_adminId = localStorage.getItem("vidyalay_userData_adminId");
  const local_institutionId = localStorage.getItem(
    "vidyalay_userData_institutionId"
  );
  const dispatch = useDispatch();

  //console.log(authInfo);

  //redux losts data after page refresh and the backend is not saving the data in cookie that's why I am saving the response in localstorage. After a page refresh, redux will lost the data but it will be saved at localstorage. If there is no data in redux, it will search data in localtorage. The problem is solved!

  useEffect(() => {
    if (authStatus === false && authInfo === null) {
      if (
        (local_accessToken?.length &&
          local_adminId?.length &&
          local_refreshToken?.length &&
          local_institutionId?.length) > 0
      ) {
        dispatch(
          login({
            status: true,
            authInfo: {
              accessToken: local_accessToken,
              refreshToken: local_refreshToken,
              adminId: local_adminId,
              institutionId: local_institutionId,
            },
          })
        );
      } else {
        dispatch(logout());
      }
    }
  }, [authInfo, authStatus]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {authStatus === true ? (
            <Route path="" element={<Dashboard />} />
          ) : (
            <Route path="" element={<Landing />} />
          )}
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="institute-profile" element={<InstituteProfle />} />
          <Route path="register-institute" element={<RegisterInstitution />} />
          <Route path="account-settings" element={<AccountSettings />} />
          <Route path="institute-bank-details" element={<InstituteBankDetails />} />
          <Route path="all-employees" element={<AllEmployees />} />
          <Route path="add-employee" element={<AddEmployee />} />
          <Route path="class" element={<Classes />} />
          <Route path="all-students" element={<AllStudents />} />
          <Route path="add-students" element={<AddStudents />} />
          <Route path="promote-students" element={<PromoteStudents />} />
          <Route path="chart-of-account" element={<ChartOfAccount />} />
          <Route path="account-statement" element={<AccountStatement />} />
        </Route>
        <Route path="/signin" element={<Signin />} />
        <Route path="/create-new-account" element={<PhoneNumberVerify />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-signup-otp" element={<OtpVerify apiRoute={`${SERVER_LINK}/otp/verify-otp`} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
