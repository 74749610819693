export default function useGroup(arr = [], key) {

    const groupObject = arr.reduce((a, e) => {
        const groupkey = e[key]

        if(!a[groupkey]) {
            a[groupkey] = []
        }

        a[groupkey].push(e)

        return a
    }, {})

    return {
        keys : Object.keys(groupObject),
        obj : groupObject
    }

}