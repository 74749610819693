import React from "react";

function Heading2({className, children}) {
  return (
    <h2 className={`${className} mt-10 scroll-m-20 text-3xl font-semibold tracking-tight transition-colors first:mt-0`}>
      {children}
    </h2>
  );
}

export default Heading2;
