import Heading from "@/components/Heading";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SERVER_LINK } from "@/constant";
import Loading from "@/components/Loading";
import { Button } from "@/components/ui/button";
import useGroup from "@/hook/useGroup";
import { setUpdateData, deleteUpdateData } from "@/store/updateSlice";
import { Link, useNavigate } from "react-router-dom";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { DropDownMenu } from "@/components/DropDownMenu";

function AllStudents() {
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const institutionId = useSelector(
    (state) => state.auth?.authInfo?.institutionId
  );
  const [group, setGroup] = useState({});
  const [groupKeys, setGroupKeys] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const dispacth = useDispatch();
  const navigate = useNavigate();

  dispacth(deleteUpdateData());

  function dateToString(str) {
    const date = new Date(str);
    let newDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      hour: "2-digit",
      month: "short",
      year: "numeric",
      minute: "2-digit",
    });
    return newDate;
  }

  async function getStudents() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/student/getstudents?institutionId=${institutionId}`,
        method: "get",
      });
      const data = res.data;
      data.map((e) => {
        e.admissionDate = dateToString(e.admissionDate);
        e.dateOfBirth = dateToString(e.dateOfBirth);
      });
      setStudents(data);
      //console.log(data);
      const group = useGroup(data, "class_");
      setGroup(group["obj"]);
      setGroupKeys(group["keys"]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function printList() {
    window.location = `${SERVER_LINK}/student/studentList?institutionId=${institutionId}`;
  }

  function admissionLetter(registrationNumber) {
    window.location = `${SERVER_LINK}/student/admission-letter?registrationNumber=${registrationNumber}&institutionId=${institutionId}`;
  }

  function studentIdCard(registrationNumber) {
    window.location = `${SERVER_LINK}/idcard/generateID?registrationNumber=${registrationNumber}&institutionId=${institutionId}`;
  }

  async function updateStatus(registrationNumber, status) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/student/update-student-status`,
        method: "post",
        data: {
          institutionId,
          registrationNumber,
          status,
        },
      });
      setResetFilter((prev) => !prev);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function deleteStudent(registrationNumber) {
    console.log(registrationNumber);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/student/delete`,
        method: "post",
        data: {
          instituteId: institutionId,
          registrationNumber,
        },
      });
      setResetFilter((prev) => !prev);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getStudents();
  }, [resetFilter, setResetFilter]);

  function filterData(query) {
    if (query.length > 0) {
      setStudents(group[query]);
    }
  }

  function defaultDataToRedux(data) {
    dispacth(setUpdateData({ updateData: data }));
    navigate("/add-students");
  }

  if (loading) {
    return <Loading color={"bg-sky-500"} />;
  }

  return (
    <div className="w-full px-10 py-5">
      <Heading>All Students</Heading>
      <div className="w-full flex items-end justify-between mt-5">
        <section className="text-[12px] flex items-center gap-4 border-[1px] px-2 py-1 rounded-md">
          <div className="flex items-center gap-2">
            <section className="w-[7px] h-[7px] bg-green-500 rounded-full"></section>{" "}
            means <span className="text-green-500 font-medium">active</span>
          </div>
          <div className="flex items-center gap-2">
            <section className="w-[7px] h-[7px] bg-red-500 rounded-full"></section>{" "}
            means <span className="text-red-500 font-medium">inactive</span>
          </div>
        </section>
        <Button asChild variant="outline">
          <Link to={"/add-students"}>Add new student</Link>
        </Button>
      </div>
      <div className="w-full flex flex-col gap-10 justify-center items-center">
        <div className="mt-5 border-[1px] w-full flex flex-col items-center gap-5 px-7 py-5 rounded-lg">
          <section className="flex gap-2">
            <select
              onChange={(e) => filterData(e.target.value)}
              className="w-[10rem] px-3 py-2 bg-white border-[1px] rounded-md text-[14px]"
            >
              <option value={""}>Select A Class</option>
              {groupKeys.map((e) =>
                e.length > 0 ? <option value={e}>{e}</option> : null
              )}
            </select>
            <Button
              variant="ghost"
              onClick={() => setResetFilter((prev) => !prev)}
            >
              Reset
            </Button>
          </section>
          <Table>
            <TableCaption>All students list</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="text-center">Name</TableHead>
                <TableHead className="text-center">Registration No</TableHead>
                <TableHead className="text-center">Date Of Birth</TableHead>
                <TableHead className="text-center">Class</TableHead>
                <TableHead className="text-center">Admission Date</TableHead>
                <TableHead className="text-center">Options</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {students.map((s) => (
                <TableRow>
                  <TableCell className="text-center flex justify-center items-center gap-3">
                    {s.status === "active" ? (
                      <section className="w-[10px] h-[10px] bg-green-500 rounded-full"></section>
                    ) : (
                      <section className="w-[10px] h-[10px] bg-red-500 rounded-full"></section>
                    )}
                    {s.name}
                  </TableCell>
                  <TableCell className="text-center">
                    {s.registrationNumber}
                  </TableCell>
                  <TableCell className="text-center">{s.dateOfBirth}</TableCell>
                  <TableCell className="text-center">{s.class_}</TableCell>
                  <TableCell className="text-center">
                    {s.admissionDate}
                  </TableCell>
                  <TableCell className="text-center">
                    <DropDownMenu>
                      <DropdownMenuItem>
                        {s.status === "inactive" ? (
                          <Button
                            variant="ghost"
                            className=" h-[20px] text-left"
                            onClick={() =>
                              updateStatus(s.registrationNumber, "active")
                            }
                          >
                            Active
                          </Button>
                        ) : (
                          <Button
                            variant="ghost"
                            className=" h-[20px] text-left"
                            onClick={() =>
                              updateStatus(s.registrationNumber, "inactive")
                            }
                          >
                            Inactive
                          </Button>
                        )}
                      </DropdownMenuItem>
                      <DropdownMenuItem>
                        <Button
                          variant="ghost"
                          className="h-[20px] text-left"
                          onClick={() => admissionLetter(s.registrationNumber)}
                        >
                          Admission Letter
                        </Button>
                      </DropdownMenuItem>
                      <DropdownMenuItem>
                        <Button
                          variant="ghost"
                          className="h-[20px] text-left"
                          onClick={() => studentIdCard(s.registrationNumber)}
                        >
                          ID Card
                        </Button>
                      </DropdownMenuItem>
                      <DropdownMenuItem>
                        <Button
                          onClick={() => defaultDataToRedux(s)}
                          variant="ghost"
                          className=" h-[20px] text-left"
                        >
                          Edit
                        </Button>
                      </DropdownMenuItem>
                      <DropdownMenuItem>
                        <Button
                          variant="ghost"
                          onClick={() => deleteStudent(s.registrationNumber)}
                          className=" h-[20px] text-left text-red-500"
                        >
                          Delete
                        </Button>
                      </DropdownMenuItem>
                    </DropDownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Button variant="outline" onClick={printList}>
          Print The List
        </Button>
      </div>
    </div>
  );
}

export default AllStudents;
