import Heading from "@/components/Heading";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import InputField from "@/components/InputField";
import Loading from "@/components/Loading";
import AlertBox from "@/components/AlertBox";
import qr from "../assets/qr.png";
import Heading2 from "@/components/Heading2";
import { Button } from "@/components/ui/button";
import CustomCard from "@/components/CustomCard";
import { SERVER_LINK } from "@/constant";
import axios from "axios";

function InstituteBankDetails() {
  const [defaultValues, setDefaultValues] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const adminId = useSelector((state) => state.auth.authInfo?.adminId);
  const institutionId = useSelector(
    (state) => state.auth.authInfo?.institutionId
  );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [enableInput, setEnableInput] = useState(false)

  async function getBankDetails() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/bankdetails/instituteBankDetails?institutionId=${institutionId}`,
        method: "get",
      });
      console.log(res.data);
      setDefaultValues(res.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getBankDetails();
  }, []);

  async function bankDetailsUpdate(data) {
    data = { ...data, institutionId: institutionId };

    //making all blank field fill with old values and update only those which is meant to be updated

    const fields = Object.keys(data)

    fields.map((e, i) => {
      if(data[e].length === 0) {
        data[e] = defaultValues[e]
      }
    })

    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/bankdetails/institute-bank-details`,
        method: "post",
        data: data,
      });
      //console.log(res.data);
      if (res.status >= 200 || res.status < 400) {
        setEnableInput(false)
        setOpen(true);
        setSuccess(true);
        setErrorMessage("Bank details is updated");
      }
    } catch (error) {
      //console.log(error);
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Bank details update failed");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full px-10 py-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <Heading>Institute's Bank Details</Heading>
      <div className="w-full flex justify-center items-center mt-[7rem]">
        <section className="w-1/2 flex flex-col justify-center items-center">
          <Heading2>Bank QR Code</Heading2>
          <img src={qr} className="w-[20rem] h-[20rem] object-cover" alt="" />
        </section>
        <div className="w-1/2">
          <CustomCard
            className={"w-[27rem]"}
            title={"Bank Details"}
            desc="Here is your institute's bank details. You can update the details."
          >
            <form
              className="w-full flex justify-center flex-col items-center col gap-5 mt-5"
              onSubmit={handleSubmit(bankDetailsUpdate)}
              onChange={() => setEnableInput(true)}
            >
              <InputField
                label="Bank Name"
                type="text"
                defaultValue={defaultValues?.bankName === null ? "" : defaultValues?.bankName}
                {...register("bankName")}
              />
              <InputField
                label="Account Number"
                type="text"
                defaultValue={
                  defaultValues?.bankAccountNumber
                }
                {...register("bankAccountNumber")}
              />
              <InputField
                label="IFS Code"
                type="text"
                defaultValue={defaultValues?.ifsCode}
                {...register("ifsCode")}
              />
              <InputField
                label="Branch Name"
                type="text"
                defaultValue={defaultValues?.branchName}
                {...register("branchName")}
              />
              {loading === true ? (
                <Loading color={"bg-sky-500"} />
              ) : enableInput ? <Button type="submit">Update</Button> : <Button type="submit" disabled>Update</Button>}
            </form>
          </CustomCard>
        </div>
      </div>
    </div>
  );
}

export default InstituteBankDetails;
