import React, { useState } from "react";
import signupImg from "../assets/signup.jpg";
import CustomCard from "@/components/CustomCard";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import AlertBox from "@/components/AlertBox";
import Loading from "@/components/Loading";
import { SERVER_LINK } from "@/constant";
import { useSelector } from "react-redux";
import { FaArrowRight } from "react-icons/fa";

function Signup() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const phoneNumber = useSelector((state) => state.otpVerify.phoneNumber);
  const navigate = useNavigate()

  async function signup(data) {
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/auth/instituteregister`,
        method: "post",
        data: data,
      });
      if (res.status >= 200 || res.status < 400) {
        setOpen(true);
        setSuccess(true);
        navigate("/signin")
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage(error?.response?.data?.error?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full flex h-[100vh]">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Sign Up Successfull"}
          desc={"Please log in to your account."}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Sign Up Failed"}
          desc={errorMessage}
        />
      )}
      <div className="w-1/2 h-[100vh] flex items-center justify-center flex-col gap-10">
        <img src={logo} className="w-[10rem]" alt="" />
        <CustomCard
          title={"Hey, Admin"}
          className={"w-[25rem]"}
          desc="Please register yourself to manage your institution"
        >
          <form className="space-y-5 mt-[1rem]" onSubmit={handleSubmit(signup)}>
            <InputField
              label={"Email"}
              placeholder={"E.g, abcd@example.com"}
              type={"email"}
              {...register("email", {
                required: true,
              })}
            />
            {errors?.email && (
              <p className="text-red-500 text-[14px]">*Email is required</p>
            )}
            <InputField
              label={"Mobile Number"}
              placeholder={"E.g, 9587456983"}
              type={"text"}
              value={phoneNumber}
              {...register("mobileNumber", {
                pattern: {
                  value: /^\+\d+$/,
                  message: "Please include country code",
                },
              })}
            />
            {errors?.mobileNumber && (
              <p className="text-red-500 text-[14px]">
                {errors?.mobileNumber?.message}
              </p>
            )}
            <InputField
              label={"Password"}
              placeholder={"E.g, Abcd@345"}
              type={"password"}
              {...register("password", {
                pattern: {
                  value:
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                  message:
                    "Minimum an uppercase letter, a lowercase letter, a number and a special character is required",
                },
                min: {
                  value: 8,
                  message: "Password should contain minimum 8 characters",
                },
              })}
            />
            {errors?.password && (
              <p className="text-red-500 text-[14px]">
                {errors?.password?.message}
              </p>
            )}
            <Button className="w-full text-[16px]">
              {loading === true ? <Loading /> : "Sign Up"}
            </Button>
          </form>
        </CustomCard>
        <span className="flex items-center gap-2">
          <Link
            to={"/signin"}
            className="text-sky-400 font-extrabold text-[18px] underline"
          >
            Login
          </Link>
          <FaArrowRight size={"15px"} className="text-sky-400" />
        </span>
      </div>
      <div
        className={`w-1/2 h-[100vh] bg-center bg-cover`}
        style={{ backgroundImage: `url("${signupImg}")` }}
      >
        <div className="w-full h-[100vh] bg-[#0000009c]">
          <div className="w-full pt-[15rem] flex flex-col pl-[5rem] justify-center">
            <p className="text-[19px] opacity-70 text-white">Welcome to</p>
            <h1 className="text-[90px] font-extrabold text-white">Vidyalay</h1>
            <p className="text-[20px] opacity-80 font-semibold text-white">
              Manage Your Institution Seemlessly...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
