import Heading from "@/components/Heading";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { SERVER_LINK } from "@/constant";
import Loading from "@/components/Loading";
import { useSelector } from "react-redux";
import Heading2 from "@/components/Heading2";
import Paragraph from "@/components/Paragraph";
import InputField from "@/components/InputField";
import { useForm } from "react-hook-form";
import SelectField from "@/components/SelectField";
import { Button } from "@/components/ui/button";
import InputFile from "@/components/InputFile";
import AlertBox from "@/components/AlertBox";
import signupImg from "../assets/signup.jpg";
import { Link } from "react-router-dom";
import imageCompression from "browser-image-compression";

function InstituteProfle() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isRegistered, setIsRegistered] = useState(undefined);
  const institutionId = useSelector(
    (state) => state?.auth?.authInfo?.institutionId
  );
  const adminId = useSelector((state) => state?.auth?.authInfo?.adminId);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const formData = new FormData();
  const { register: registerLogo, handleSubmit: handleLogo } = useForm();
  const { register: registerLetterhead, handleSubmit: handleLetterhead } =
    useForm();
  const { register: registerSign, handleSubmit: handleSign } = useForm();
  const { register: registerQR, handleSubmit: handleQR } = useForm();
  const [imageLoading, setImageLoading] = useState(false);
  const [updateLogo, setUpdateLogo] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});

  async function fetchInstitutionDetails() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/institute/instituteid?institutionID=${institutionId}`,
        method: "get",
      });
      console.log(res);
      if (res.status >= 200 || res.status < 400) {
        setIsRegistered(true);
        setDefaultValues(res.data);
      }
    } catch (error) {
      const errorStatus = error?.response?.data?.error?.status;
      if (errorStatus === 404) {
        setIsRegistered(false);
      }
    } finally {
      setLoading(false);
    }
  }

  async function updateData(data) {
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/institute/updateinstitute`,
        method: "post",
        data: data,
      });
      //console.log(res);
      if (res.status >= 200 || res.status < 400) {
        setOpen(true);
        setSuccess(true);
        setErrorMessage("Update Successfull");
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage(error?.response?.data?.error?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchInstitutionDetails();
  }, [institutionId]);

  async function uploadLogo(logoData) {
    //console.log(logoData);

    // let title = logoData.logoData[0].name;
    // title = title.split(".");
    // const titleLen = title.length;
    // let newTitle = `${institutionId}.${title[titleLen - 1]}`;

    const reader = new FileReader();

    setImageLoading(true);

    const options = {
      maxSizeMB: 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    const compressedFile = await imageCompression(logoData.logoData[0], options)

    reader.readAsDataURL(compressedFile);

    reader.onloadend = async () => {
      const imgBase64String = reader.result;

      let fileData = {
        image: imgBase64String,
        title: institutionId,
      };

      console.log(fileData);

      try {
        const res = await axios({
          url: `${SERVER_LINK}/images/uploadDL`,
          method: "post",
          data: fileData,
        });
        console.log(res);
        if (res.status >= 200 || res.status < 400) {
          setOpen(true);
          setSuccess(true);
          setErrorMessage("Image is uploaded successfully");
        }
      } catch (error) {
        setOpen(true);
        setSuccess(false);
        setErrorMessage(error?.response?.data?.error?.message);
      } finally {
        setImageLoading(false);
      }
    };

    reader.onerror = () => {
      console.error("Error reading file");
    };
  }
  async function uploadSign(signData) {
    console.log(signData);
  }
  async function uploadQR(qrData) {
    console.log(qrData);
  }
  async function uploadLetter(letterData) {
    console.log(letterData);
  }

  return (
    <div className="w-full px-10 py-5">
      {loading && <Loading />}
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Sign Up Failed"}
          desc={errorMessage}
        />
      )}
      <Heading>Institute Profile</Heading>
      <div className="w-full flex flex-col justify-center items-center">
        {!isRegistered && (
          <div className="flex flex-col justify-center items-center gap-3">
            <Heading2>You have not registered your institution yet</Heading2>
            <Paragraph className={"opacity-70"}>
              Please register your institution here
            </Paragraph>
            <Button variant="outline">
              <Link className="w-full" to={"/register-institute"}>
                Register
              </Link>
            </Button>
          </div>
        )}
        {isRegistered && (
          <div className="w-full flex gap-10">
            <div className="w-full flex flex-col gap-[5rem]">
              <div className="w-full flex justify-center items-center gap-10 flex-wrap">
                <section className="w-full flex flex-col justify-center items-center gap-5">
                  <img
                    src={signupImg}
                    className="w-[10rem] h-[10rem] rounded-full"
                    alt=""
                  />
                  <form
                    className="flex items-center justify-center"
                    onSubmit={handleLogo(uploadLogo)}
                  >
                    {imageLoading === true ? (
                      <Loading color={"bg-sky-500"} />
                    ) : (
                      <InputFile
                        title="Logo"
                        required
                        {...registerLogo("logoData")}
                      />
                    )}
                  </form>
                </section>
              </div>
              <div className="w-full flex justify-around flex-wrap items-center gap-5">
                <section className="flex flex-col justify-center items-center gap-5">
                  <img src={signupImg} className="w-[10rem]" alt="" />
                  <form
                    className="flex items-center justify-center"
                    onSubmit={handleSign(uploadSign)}
                  >
                    {imageLoading === true ? (
                      <Loading color={"bg-sky-500"} />
                    ) : (
                      <InputFile
                        title="Principle Signature"
                        required
                        {...registerSign("signData")}
                      />
                    )}
                  </form>
                </section>
                <section className="flex flex-col justify-center items-center gap-5">
                  <img src={signupImg} className="w-[10rem]" alt="" />
                  <form
                    className="flex items-center justify-center"
                    onSubmit={handleQR(uploadQR)}
                  >
                    {imageLoading === true ? (
                      <Loading color={"bg-sky-500"} />
                    ) : (
                      <InputFile
                        title="Bank QR Code"
                        required
                        {...registerQR("qrData")}
                      />
                    )}
                  </form>
                </section>
                <section className=" flex flex-col justify-center items-center gap-5">
                  <img src={signupImg} className="w-[10rem]" alt="" />
                  <form
                    className="flex items-center justify-center"
                    onSubmit={handleLetterhead(uploadLetter)}
                  >
                    {imageLoading === true ? (
                      <Loading color={"bg-sky-500"} />
                    ) : (
                      <InputFile
                        title="Letter Head"
                        required
                        {...registerLetterhead("letterData")}
                      />
                    )}
                  </form>
                </section>
              </div>
              <form
                className="w-full flex flex-col gap-10 items-center justify-center"
                onSubmit={handleSubmit(updateData)}
              >
                <div className="w-full flex flex-wrap gap-10 mt-5 items-center justify-center">
                  <InputField
                    label="Institution ID"
                    type="text"
                    value={institutionId}
                    {...register("institutionID")}
                  />
                  <InputField
                    label="Name"
                    type="text"
                    defaultValue={
                      defaultValues?.name === null ? "" : defaultValues?.name
                    }
                    {...register("name")}
                  />
                  <InputField
                    label="Target Line"
                    type="text"
                    defaultValue={
                      defaultValues?.targetLine === null
                        ? ""
                        : defaultValues?.targetLine
                    }
                    {...register("targetLine")}
                  />
                  <InputField
                    label="Website"
                    type="text"
                    defaultValue={
                      defaultValues?.website === null
                        ? ""
                        : defaultValues?.website
                    }
                    {...register("website")}
                  />
                  <InputField
                    label="Email"
                    type="text"
                    defaultValue={
                      defaultValues?.email === null ? "" : defaultValues?.email
                    }
                    {...register("email")}
                  />
                  <InputField
                    label="Mobile"
                    type="text"
                    defaultValue={
                      defaultValues?.mobile === null
                        ? ""
                        : defaultValues?.mobile
                    }
                    {...register("mobile")}
                  />
                </div>
                <Button className="w-[10rem]" type="submit">
                  {loading === true ? <Loading color={"bg-white"} /> : "Update"}
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default InstituteProfle;
