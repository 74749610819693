import React, { useEffect, useState } from 'react'
import logo from "../assets/logo.png"
import { Button } from './ui/button'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Header() {

  const authStatus = useSelector(state => state.auth.status)

  if(authStatus) {
    return <div></div>
  }

  return (
    <header className='w-full flex justify-around items-center py-5 bg-sky-50'>
      <section>
        <img src={logo} className='w-[8rem]' alt="" />
      </section>
      <section className='flex gap-5'>
        <Button className="uppercase"><Link className='w-full font-medium' to={"/create-new-account"}>Create New Account</Link></Button>
        <Button variant="ghost" className="uppercase"><Link className='w-full font-medium' to={"/signin"}>Sign In</Link></Button>
      </section>
    </header>
  )
}

export default Header