import React from "react";

function Heading4({className, children}) {
  return (
    <h4 className={`${className} scroll-m-20 text-xl font-semibold tracking-tight`}>
      {children}
    </h4>
  );
}

export default Heading4;
