import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: false,
    authInfo: null
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        login : (state, action) => {
            state.status = action.payload.status,
            state.authInfo = action.payload.authInfo
        },
        logout: (state, action) => {
            state.status = false,
            state.authInfo = null
        }
    }
})

export const {login, logout} = authSlice.actions
export default authSlice.reducer