import Heading from "@/components/Heading";
import React, { useState, useEffect } from "react";
import InputField from "@/components/InputField";
import SelectField from "@/components/SelectField";
import { useForm } from "react-hook-form";
import axios from "axios";
import { SERVER_LINK } from "@/constant";
import InputFile from "@/components/InputFile";
import Loading from "@/components/Loading";
import { Button } from "@/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "@/components/AlertBox";
import { login, logout } from "@/store/authSlice";
import { useNavigate } from "react-router-dom";

function RegisterInstitution() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const { register: registerLogo, handleSubmit: handleLogo } = useForm();
  const { register: registerLetterhead, handleSubmit: handleLetterhead } =
    useForm();
  const { register: registerSign, handleSubmit: handleSign } = useForm();
  const { register: registerQR, handleSubmit: handleQR } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const currency = watch("currency");
  const formData = new FormData();
  const accessToken = localStorage.getItem("vidyalay_userData_accessToken");
  const refreshToken = localStorage.getItem("vidyalay_userData_refreshToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminId = useSelector((state) => state.auth.authInfo?.adminId);
  const institutionId = useSelector(
    (state) => state.auth.authInfo?.institutionId
  );
  const [imageLoading, setImageLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  async function registerInstitution(data) {
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/institute/create`,
        method: "post",
        data: data,
      });
      if (res.status >= 200 || res.status < 400) {
        dispatch(
          login({
            status: true,
            authInfo: {
              accessToken,
              refreshToken,
              adminId,
              institutionId: res.data.institutionID,
            },
          })
        );
        localStorage.setItem("vidyalay_userData_institutionId", res.data.institutionID)
        setOpen(true);
        setSuccess(true);
        setErrorMessage("Registered successfully")
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage(error?.response?.data?.error?.message);
    } finally {
      setLoading(false);
    }
  }

  async function uploadLogo(logoData) {
    let title = logoData.logoData[0].name;
    title = title.split(".");
    const titleLen = title.length;
    let newTitle = `${institutionId}.${title[titleLen - 1]}`;

    const reader = new FileReader();

    reader.readAsDataURL(logoData.logoData[0]);

    setImageLoading(true);

    reader.onloadend = async () => {
      const imgBase64String = reader.result;

      let fileData = {
        image: imgBase64String,
        title: newTitle,
      };

      try {
        const res = await axios({
          url: `${SERVER_LINK}/images/uploadDL`,
          method: "post",
          data: fileData,
        });
        console.log(res);
        if (res.status >= 200 || res.status < 400) {
          setOpen(true);
          setSuccess(true);
          setErrorMessage("Image is uploaded successfully");
        }
      } catch (error) {
        setOpen(true);
        setSuccess(false);
        setErrorMessage(error?.response?.data?.error?.message);
      } finally {
        setImageLoading(false);
      }
    };

    reader.onerror = () => {
      console.error("Error reading file");
    };
  }
  async function uploadSign(signData) {
    console.log(signData);
  }
  async function uploadQR(qrData) {
    console.log(qrData);
  }
  async function uploadLetter(letterData) {
    console.log(letterData);
  }

  return (
    <div className="w-full py-5 px-10">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Sign Up Failed"}
          desc={errorMessage}
        />
      )}
      <Heading>Register Your Institution</Heading>
      <div className="w-full flex flex-col items-center gap-10 mt-5">
        <form
          className="w-full flex flex-col gap-10 items-center justify-center"
          onSubmit={handleSubmit(registerInstitution)}
        >
          <div className="w-full flex flex-wrap gap-10 mt-5 items-center justify-center">
            <InputField
              label="Institution ID"
              type="text"
              {...register("institutionID")}
            />
            <InputField label="Name" type="text" {...register("name")} />
            <InputField label="Board" type="text" {...register("board")} />
            <InputField label="Country" type="text" {...register("country")} />
            <SelectField
              label="Currency"
              valueArray={["INR", "USD"]}
              placeholder="Currency"
              {...register("currency")}
              value={currency}
              onChange={(value) => setValue("currency", value)}
            />
            <InputField label="Address" type="text" {...register("address")} />
            <InputField
              label="Academic Session"
              type="text"
              {...register("academicSession")}
            />
            <InputField label="Email" type="text" {...register("email")} />
            <InputField label="Mobile" type="text" {...register("mobile")} />
            <InputField
              label="Admin ID"
              value={adminId}
              type="text"
              {...register("adminId")}
            />
          </div>
          <Button className="w-[10rem]" type="submit">
            {loading === true ? <Loading color={"bg-white"} /> : "Register"}
          </Button>
        </form>
        <div className="w-full flex justify-center items-center gap-10 mt-5 flex-wrap">
          <form
            className="w-2/5 flex items-center justify-center"
            onSubmit={handleLogo(uploadLogo)}
          >
            {imageLoading === true ? (
              <Loading color={"bg-sky-500"} />
            ) : (
              <InputFile title="Logo" required {...registerLogo("logoData")} />
            )}
          </form>
          <form
            className="w-2/5 flex items-center justify-center"
            onSubmit={handleSign(uploadSign)}
          >
            <InputFile
              title="Principle Signature"
              required
              {...registerSign("signData")}
            />
          </form>
          <form
            className="w-2/5 flex items-center justify-center"
            onSubmit={handleQR(uploadQR)}
          >
            <InputFile
              title="Bank QR Code"
              required
              {...registerQR("qrData")}
            />
          </form>
          <form
            className="w-2/5 flex items-center justify-center"
            onSubmit={handleLetterhead(uploadLetter)}
          >
            <InputFile
              title="Letter Head"
              required
              {...registerLetterhead("letterData")}
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegisterInstitution;
