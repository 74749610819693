import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateData: null
}

const updateSlice = createSlice({
    name: "updateData",
    initialState,
    reducers: {
        setUpdateData : (state, action) => {
            state.updateData = action.payload.updateData
        },
        deleteUpdateData : (state, action) => {
            state.updateData = null
        }
    }
})

export const {setUpdateData, deleteUpdateData} = updateSlice.actions
export default updateSlice.reducer