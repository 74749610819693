import Heading from "@/components/Heading";
import React, {useState} from "react";
import { useForm } from "react-hook-form";
import InputField from "@/components/InputField";
import { useSelector } from "react-redux";
import DialogBox from "@/components/DialogBox";
import { IoMdArrowDropright } from "react-icons/io";
import { Button } from "@/components/ui/button";
import { SERVER_LINK } from "@/constant";
import AlertBox from "@/components/AlertBox";
import axios from "axios";
import Loading from "@/components/Loading";

function AccountSettings() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const adminId = useSelector((state) => state.auth.authInfo?.adminId);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function updateAccount(data) {
    console.log(data);

    if (data.newPassword != data.confirmPassword) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Password does not match");
      return
    }

    const updateData = {
      adminId: adminId,
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    };

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/auth/updatePassword`,
        method: "post",
        data: updateData,
      });
      console.log(res.data);
      if (res.status >= 200 || res.status < 400) {
        setOpen(true);
        setSuccess(true);
        setErrorMessage("Password is updated");
      }
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Password update failed");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full px-10 py-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <Heading>Account Settings</Heading>
      <div className="w-full flex justify-center mt-20 items-center">
        <DialogBox
          title={"Update Password"}
          icon={<IoMdArrowDropright size={"22px"} className="text-slate-600" />}
          buttonClass={
            "w-[50vw] flex items-center justify-between font-normal h-[3.2rem] text-slate-900"
          }
        >
          <form
            className="w-full flex justify-center flex-col items-center col gap-5 mt-5"
            onSubmit={handleSubmit(updateAccount)}
          >
            <InputField
              label="Current Password"
              type="password"
              {...register("currentPassword")}
            />
            <InputField
              label="New Password"
              type="password"
              {...register("newPassword")}
            />
            <InputField
              label="Confirm Password"
              type="password"
              {...register("confirmPassword")}
            />
            {loading === true? <Loading color={"bg-sky-500"}/> : <Button type="submit">Change Password</Button>}
          </form>
        </DialogBox>
      </div>
    </div>
  );
}

export default AccountSettings;
