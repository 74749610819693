import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import React from "react";
import { Button } from "./ui/button";

const InputFile = React.forwardRef(function InputFile(
  { title, ...props },
  ref
) {
  return (
    <div className="grid w-full max-w-sm items-center gap-1.5">
      <Label htmlFor="picture">{title}</Label>
      <div className="w-full flex justify-center items-center gap-2">
        <Input id="picture" type="file" {...props} ref={ref} />
        <Button className="w-[5rem]" type="submit">
          Upload
        </Button>
      </div>
    </div>
  );
});

export default InputFile;
