import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    phoneNumber: null
}

const otpSlice = createSlice({
    name: "otpVerify",
    initialState,
    reducers: {
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload.phoneNumber
        }
    }
})

export const {setPhoneNumber} = otpSlice.actions
export default otpSlice.reducer