import React from "react";
import appnest from "@/assets/appnest_logo.png";
import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube, BsThreads } from "react-icons/bs";
import { Link } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { CiGlobe } from "react-icons/ci";
import { Button } from "./ui/button";
import { FaGooglePlay } from "react-icons/fa";
import { FaAppStoreIos } from "react-icons/fa";
import { useSelector } from "react-redux";
import DialogBox from "./DialogBox";
import Heading from "./Heading";
import { tAndC, pAndP, cAndR, decl } from "@/t&c";
import { IoCallOutline } from "react-icons/io5";

function Footer() {
  const authStatus = useSelector((state) => state?.auth?.status);

  if (authStatus) {
    return <div></div>;
  }

  return (
    <footer className="flex flex-col justify-center px-10 items-center py-10 mt-20 bg-sky-50">
      <div className="flex w-full justify-center gap-[3rem] lg:gap-[10rem] flex-wrap py-20 items-center">
        <div className="flex flex-col justify-center items-center gap-10">
          <img src={appnest} className="w-[20rem]" alt="" />
          <section className="flex gap-5 text-[25px] mt-5">
            <Link to={"https://www.facebook.com/profile.php?id=61556775867975&mibextid=kFxxJD"}><BsFacebook className="text-blue-500" /></Link>
            <Link to={"https://www.instagram.com/appnestit?igsh=ZHpla3AwYmdhNjFm"}><BsInstagram className="text-pink-500" /></Link>
            <Link to={"https://in.linkedin.com/company/appnestit"}><BsLinkedin className="text-sky-500" /></Link>
            <Link to={"https://youtube.com/@AppNestit?si=ybmJ3KeM0_9_4HRZ"}><BsYoutube className="text-red-500" /></Link>
            <Link to={"https://www.threads.net/@appnestit"}><BsThreads className="text-black" /></Link>
          </section>
        </div>
        <div className="flex flex-col justify-start items-start gap-1 text-slate-600">
          <Link className="flex items-center justify-center gap-2">
            <AiOutlineMail /> vidyalay@appnestit.com
          </Link>
          <Link className="flex items-center justify-center gap-2">
            <CiGlobe /> www.appnestit.com
          </Link>
          <Link className="flex items-center justify-center gap-2">
            <IoCallOutline /> +91 9148054905
          </Link>
        </div>
        <div className="flex flex-col justify-center items-center gap-5">
          <Link to={"https://play.google.com/store/apps/details?id=com.colorcode.vidyalaya.admin"}>
            <section className="flex justify-center items-center bg-slate-800 p-3 text-white rounded-full gap-3">
              <FaGooglePlay size={"25px"} />
              <div>
                <p className="text-[8px]">GET ON</p>
                <p className="text-[15px] font-medium">Google Play Store</p>
              </div>
            </section>
          </Link>
          <Link>
            <section className="flex justify-center items-center bg-slate-800 p-3 text-white rounded-full gap-3">
              <FaAppStoreIos size={"25px"} />
              <div>
                <p className="text-[8px]">GET ON</p>
                <p className="text-[15px] font-medium">iOS App Store</p>
              </div>
            </section>
          </Link>
        </div>
      </div>
      <div className="w-full h-[1px] bg-slate-300 mb-5"></div>
      <p className="text-slate-700">
        Office Address : S.No, ALPINE VIVA, 139, Whitefield - Hoskote Rd, Sai
        Gardens, Seegehalli, Krishnarajapura, Bengaluru, Karnataka, 560067
      </p>
      <p className="mt-5 text-[14px] text-slate-600">
        COPYRIGHT © 2024 - AppNest Information Technology Private Limited, India
        •{" "}
        {
          <DialogBox
            tAndCs={true}
            title={"Privacy & Policy"}
            buttonClass={"border-none bg-transparent"}
          >
            <Heading>Privacy & Policy</Heading>
            <p>{pAndP}</p>
          </DialogBox>
        }{" "}
        •{" "}
        {
          <DialogBox
            tAndCs={true}
            title={"Terms & Conditions"}
            buttonClass={"border-none bg-transparent"}
          >
            <Heading>Terms & Conditions</Heading>
            <p>{tAndC}</p>
          </DialogBox>
        }{" "}
        •{" "}
        {
          <DialogBox
            tAndCs={true}
            title={"Cancellation & Refund"}
            buttonClass={"border-none bg-transparent"}
          >
            <Heading>Cancellation & Refund</Heading>
            <p>{cAndR}</p>
          </DialogBox>
        }•{" "}
        {
          <DialogBox
            tAndCs={true}
            title={"Decleration"}
            buttonClass={"border-none bg-transparent"}
          >
            <Heading>Decleration</Heading>
            <p>{decl}</p>
          </DialogBox>
        }{" "}
      </p>
    </footer>
  );
}

export default Footer;
