import React, { useState } from "react";
import signupImg from "../assets/signup.jpg";
import CustomCard from "@/components/CustomCard";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { useForm } from "react-hook-form";
import axios from "axios";
import AlertBox from "@/components/AlertBox";
import Loading from "@/components/Loading";
import { SERVER_LINK } from "@/constant";
import { useDispatch } from "react-redux";
import {login, logout} from "../store/authSlice.js"
import { useNavigate } from "react-router-dom";

function Signin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isRegistered, setIsRegistered] = useState(undefined)

  async function signup(data) {
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/auth/institutelogin`,
        method: "post",
        data: data,
      });
      if (res.status >= 200 || res.status < 400) {
        //saving data in redux
        dispatch(login({authInfo: res.data, status: true}))
        //saving data in localstorage
        localStorage.setItem("vidyalay_userData_accessToken", res.data.accessToken)
        localStorage.setItem("vidyalay_userData_refreshToken", res.data.refreshToken)
        localStorage.setItem("vidyalay_userData_adminId", res.data.adminId)
        localStorage.setItem("vidyalay_userData_institutionId", res.data.institutionId)
        setOpen(true);
        setSuccess(true);
        if(res.data.institutionId == "name123") {
          console.log("not registered");
          navigate("/register-institute")
          return
        }
        navigate("/")
      }
    } catch (error) {
      //console.log(error.response.data.error.message);
      dispatch(logout())
      setOpen(true);
      setSuccess(false);
      setErrorMessage(error?.response?.data?.error?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full flex h-[100vh]">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Sign Up Successfull"}
          desc={"Please log in to your account."}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Sign Up Failed"}
          desc={errorMessage}
        />
      )}
      <div className="w-1/2 h-[100vh] flex items-center justify-center flex-col gap-10">
        <img src={logo} className="w-[10rem]" alt="" />
        <CustomCard
          title={"Welcome Back"}
          className={"w-[25rem]"}
          desc="Sign in to your account"
          footer={
            <p className="text-center w-full text-[12px] text-slate-500">
              Don't have an account?{" "}
              <Link className="underline" to={"/create-new-account"}>
                Create new account
              </Link>
            </p>
          }
        >
          <form className="space-y-5 mt-[1rem]" onSubmit={handleSubmit(signup)}>
            <InputField
              label={"Email"}
              placeholder={"E.g, abcd@example.com"}
              type={"email"}
              {...register("email", {
                required: true,
              })}
            />
            {errors?.email && (
              <p className="text-red-500 text-[14px]">*Email is required</p>
            )}
            <InputField
              label={"Password"}
              placeholder={"E.g, Abcd@345"}
              type={"password"}
              {...register("password", {
                required: true,
              })}
            />
            {errors?.password && (
              <p className="text-red-500 text-[14px]">
                *Password is required
              </p>
            )}
            <Button className="w-full text-[16px]">
              {loading === true ? <Loading color={"bg-[#fff]"} /> : "Sign In"}
            </Button>
          </form>
        </CustomCard>
      </div>
      <div
        className={`w-1/2 h-[100vh] bg-center bg-cover`}
        style={{ backgroundImage: `url("${signupImg}")` }}
      >
        <div className="w-full h-[100vh] bg-[#0000009c]">
          <div className="w-full pt-[15rem] flex flex-col pl-[5rem] justify-center">
            <p className="text-[19px] opacity-70 text-white">Welcome back</p>
            <h1 className="text-[90px] font-extrabold text-white">Sign In</h1>
            <p className="text-[20px] opacity-80 font-semibold text-white">
              Manage Your Institution Seemlessly...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signin;
