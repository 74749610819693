import React, { useState } from "react";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClass } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";
import { PiBooksThin } from "react-icons/pi";
import { IoPersonOutline } from "react-icons/io5";
import { IoBagOutline } from "react-icons/io5";
import { CiWallet } from "react-icons/ci";
import { CiMoneyBill } from "react-icons/ci";
import { MdOutlineLocalAtm } from "react-icons/md";
import { FaRegHandPaper } from "react-icons/fa";
import { CiCalendarDate } from "react-icons/ci";
import { FaPencilAlt } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { MdOutlineLocalGroceryStore } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { IoChatboxOutline } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";
import { MdLiveTv } from "react-icons/md";
import { FaPaperclip } from "react-icons/fa6";
import { PiExamLight } from "react-icons/pi";
import { LuFileSpreadsheet } from "react-icons/lu";
import { TbFileReport } from "react-icons/tb";
import { LiaCertificateSolid } from "react-icons/lia";
import { IoHomeOutline } from "react-icons/io5";
import { MdEmojiTransportation } from "react-icons/md";
import { FaBed } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { FaMinus } from "react-icons/fa";
import { Button } from "./ui/button";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "@/store/authSlice";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.status);
  const [open, setOpen] = useState(null);

  function userLogout() {
    try {
      localStorage.removeItem("vidyalay_userData_accessToken");
      localStorage.removeItem("vidyalay_userData_refreshToken");
      localStorage.removeItem("vidyalay_userData_adminId");
      localStorage.removeItem("vidyalay_userData_institutionId");
      dispatch(logout());
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  }

  const items = [
    {
      heading: "General Settings",
      icon: <CiSettings size={"15px"} />,
      subNavArray: [
        {
          headings: "Institute Profile",
          link: "/institute-profile",
        },
        {
          headings: "Account Settings",
          link: "/account-settings",
        },
        {
          headings: "Institute Bank Details",
          link: "/institute-bank-details",
        },
      ],
    },
    {
      heading: "Classes",
      icon: <MdOutlineClass size={"15px"} />,
      link: "/class",
    },
    {
      heading: "Subjects",
      icon: <PiBooksThin size={"15px"} />,
      subNavArray: [
        {
          headings: "Classes With Subjects",
          link: "/profile",
        },
        {
          headings: "Add New Subjects",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Students",
      icon: <IoPersonOutline size={"15px"} />,
      subNavArray: [
        {
          headings: "All Students",
          link: "/all-students",
        },
        {
          headings: "Add Students",
          link: "/add-students",
        },
        {
          headings: "Promote Students",
          link: "/promote-students",
        },
      ],
    },
    {
      heading: "Employees",
      icon: <IoBagOutline size={"15px"} />,
      subNavArray: [
        {
          headings: "All Employees",
          link: "/all-employees",
        },
        {
          headings: "Add Employee",
          link: "/add-employee",
        },
      ],
    },
    {
      heading: "Account",
      icon: <CiWallet size={"15px"} />,
      subNavArray: [
        {
          headings: "Chart of Account",
          link: "/chart-of-account",
        },
        {
          headings: "Account Statement",
          link: "/account-statement",
        },
      ],
    },
    {
      heading: "Fees",
      icon: <CiMoneyBill size={"15px"} />,
      subNavArray: [
        {
          headings: "Collect Fee",
          link: "/profile",
        },
        {
          headings: "Fee Slip",
          link: "/profile",
        },
        {
          headings: "Fees Defaulters",
          link: "/profile",
        },
        {
          headings: "Fee Setup",
          link: "/profile",
        },
        {
          headings: "Generate Bank Challan",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Salary",
      icon: <MdOutlineLocalAtm size={"15px"} />,
      subNavArray: [
        {
          headings: "Pay Salary",
          link: "/profile",
        },
        {
          headings: "Salary Slip",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Attendance",
      icon: <FaRegHandPaper size={"15px"} />,
      subNavArray: [
        {
          headings: "MArk Student Attendance",
          link: "/profile",
        },
        {
          headings: "Mark Employee Attendance",
          link: "/profile",
        },
        {
          headings: "Class Attendance Report",
          link: "/profile",
        },
        {
          headings: "Student Attendance Report",
          link: "/profile",
        },
        {
          headings: "Employee Attendance Report",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Timetable",
      icon: <CiCalendarDate size={"15px"} />,
      subNavArray: [
        {
          headings: "Create Timetable",
          link: "/profile",
        },
        {
          headings: "Get Timetable",
          link: "/profile",
        },
        {
          headings: "Update Timetable",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Homework",
      icon: <FaPencilAlt size={"15px"} />,
      subNavArray: [
        {
          headings: "Add Homework",
          link: "/profile",
        },
        {
          headings: "All Homeworks",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Behavioour & Skills",
      icon: <FaRegEye size={"15px"} />,
      subNavArray: [
        {
          headings: "Rate Behaviours",
          link: "/profile",
        },
        {
          headings: "Rate Skills",
          link: "/profile",
        },
        {
          headings: "Observations",
          link: "/profile",
        },
        {
          headings: "See Report",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Transport",
      icon: <MdEmojiTransportation size={"15px"} />,
      subNavArray: [
        {
          headings: "Routes",
          link: "/profile",
        },
        {
          headings: "Vehicals",
          link: "/profile",
        },
        {
          headings: "Transport Info",
          link: "/profile",
        },
        {
          headings: "Assign Vehicle",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Hostel",
      icon: <FaBed size={"15px"} />,
      subNavArray: [
        {
          headings: "Add Room",
          link: "/profile",
        },
        {
          headings: "Allocate Room",
          link: "/profile",
        },
        {
          headings: "Allocated Room List",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Online Store",
      icon: <MdOutlineLocalGroceryStore size={"15px"} />,
      subNavArray: [
        {
          headings: "Store Analytics",
          link: "/profile",
        },
        {
          headings: "Product Catagories",
          link: "/profile",
        },
        {
          headings: "Product Tax",
          link: "/profile",
        },
        {
          headings: "Products",
          link: "/profile",
        },
        {
          headings: "New Order",
          link: "/profile",
        },
        {
          headings: "All Order",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Technical Support",
      icon: <FaWhatsapp size={"15px"} />,
    },
    {
      heading: "Push Notification",
      icon: <IoChatboxOutline size={"15px"} />,
      subNavArray: [
        {
          headings: "Send Notification",
          link: "/profile",
        },
        {
          headings: "All Notification",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Live Classes",
      icon: <MdLiveTv size={"15px"} />,
    },
    {
      heading: "Exams",
      icon: <PiExamLight size={"15px"} />,
      subNavArray: [
        {
          headings: "Create New Exams",
          link: "/profile",
        },
        {
          headings: "All Exams",
          link: "/profile",
        },
        {
          headings: "Add & Update Exam Marks",
          link: "/profile",
        },
        {
          headings: "Result Cards",
          link: "/profile",
        },
        {
          headings: "Blank Award List",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Class Tests",
      icon: <LuFileSpreadsheet size={"15px"} />,
      subNavArray: [
        {
          headings: "Create New Tests",
          link: "/profile",
        },
        {
          headings: "Test Result",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Reports",
      icon: <TbFileReport size={"15px"} />,
      subNavArray: [
        {
          headings: "Student Report Card",
          link: "/profile",
        },
        {
          headings: "Student Info Card",
          link: "/profile",
        },
        {
          headings: "Parents Info Card",
          link: "/profile",
        },
        {
          headings: "Students Monthly Attendance Report",
          link: "/profile",
        },
        {
          headings: "Staff Monthly Attendance Report",
          link: "/profile",
        },
        {
          headings: "Fee Collection Report",
          link: "/profile",
        },
        {
          headings: "Student Progress Report",
          link: "/profile",
        },
        {
          headings: "Accounts Report",
          link: "/profile",
        },
        {
          headings: "Customized Reports",
          link: "/profile",
        },
        {
          headings: "SMS Template",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Certificates",
      icon: <LiaCertificateSolid size={"15px"} />,
      subNavArray: [
        {
          headings: "Leave Certificate",
          link: "/profile",
        },
        {
          headings: "Character Certificate",
          link: "/profile",
        },
        {
          headings: "Certificate Templates",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Subscription",
      icon: <FaPaperclip size={"15px"} />,
      subNavArray: [
        {
          headings: "My Subscription",
          link: "/profile",
        },
        {
          headings: "All Subscription",
          link: "/profile",
        },
        {
          headings: "Payment History",
          link: "/profile",
        },
      ],
    },
    {
      heading: "Legal Policy",
      icon: <FaPaperclip size={"15px"} />,
      subNavArray: [
        {
          headings: "Terms And Conditions",
          link: "/profile",
        },
        {
          headings: "Disclaimer",
          link: "/profile",
        },
        {
          headings: "Privacy Policy",
          link: "/profile",
        },
        {
          headings: "Cancellation And Refund",
          link: "/profile",
        },
      ],
    },
  ];

  if (!authStatus) return <div></div>;

  return (
    <div className="w-[10rem] lg:w-[18rem]  h-[100vh] py-5 border-[1px] fixed rounded-sm border-slate-300 flex flex-col items-center overflow-auto">
      <img src={logo} className="w-[8rem] lg:w-[10rem]" alt="" />
      <div className="w-full flex flex-col mt-10 gap-5">
        <span className="flex items-center gap-3 border-l-[5px] border-sky-600 px-5 h-[3rem] bg-sky-100">
          <IoHomeOutline size={"15px"} />
          <Link to={"/"} className="hover:text-sky-500">
            Dashboard
          </Link>
        </span>
        <div className="px-3 w-full flex flex-col gap-5">
          {items.map((e, index) =>
            dropdown(
              e.heading,
              index,
              e.subNavArray,
              e.link,
              e.icon,
              open,
              setOpen,
              index
            )
          )}
          <Button
            className="text-[16px] bg-red-500 hover:bg-red-700 font-semibold"
            onClick={userLogout}
          >
            <IoIosLogOut className="text-white mr-3" size={"25px"} /> Logout
          </Button>
        </div>
      </div>
    </div>
  );
}

function dropdown(heading, index, subNavArray, link, icon, open, setOpen, key) {
  const openDropdown = open === index;

  return (
    <section className="w-full" key={key}>
      <div
        className={`flex justify-between cursor-pointer items-center w-full px-4 rounded-2xl font-medium ${
          openDropdown === true
            ? "bg-sky-500 hover:text-white text-white font-medium py-2"
            : "bg-white text-slate-500"
        }`}
        onClick={() => setOpen(openDropdown ? null : index)}
      >
        <span className="flex items-center gap-3">
          {icon}
          {subNavArray && <h1 className="hover:text-sky-500">{heading}</h1>}
          {!subNavArray && (
            <h1 className="hover:text-sky-500">
              <Link to={link} className="w-full">
                {heading}
              </Link>
            </h1>
          )}
        </span>
        {subNavArray && openDropdown === true ? (
          <FaMinus size={"12px"} className="text-slate-500 hidden lg:block" />
        ) : subNavArray?.length > 0 ? (
          <FaPlus size={"12px"} className="text-slate-500 hidden lg:block" />
        ) : null}
      </div>
      <ul className="w-full mt-2 border-l-[1px] pl-[1.5rem] space-y-3 border-sky-500">
        {openDropdown &&
          subNavArray &&
          subNavArray.map((e, index) => (
            <li key={index}>
              <Link
                to={e.link}
                className="text-slate-400 hover:text-slate-600 w-full"
              >
                {e.headings}
              </Link>
            </li>
          ))}
      </ul>
    </section>
  );
}

export default Navbar;
