import React from "react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import Heading from "@/components/Heading";
import Paragraph from "@/components/Paragraph";
import laptop from "@/assets/laptop.png";
import Heading2 from "@/components/Heading2";
import {
  BookOpenCheck,
  Shield,
  Bus,
  BellRing,
  CreditCard,
  DollarSign,
  ShoppingCart,
  BookType,
} from "lucide-react";
import desktop from "@/assets/desktop.png";
import students from "@/assets/communicate.png";
import mobileImg from "@/assets/mobile.png";
import collegeImg from "@/assets/college.jpg";
import androidApp from "@/assets/app.png";
import teamImg from "@/assets/support-team.png";
import Heading4 from "@/components/Heading4";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { BsBank } from "react-icons/bs";
import { AiOutlineAccountBook } from "react-icons/ai";
import { BsWallet2 } from "react-icons/bs";
import { IoBookOutline } from "react-icons/io5";
import { FaPeopleGroup } from "react-icons/fa6";
import AccredionBox from "@/components/AccredionBox";

function Landing() {

  const faqArray = [
    {
      trigger: "What features does the school management ERP software offer?",
      content:
        "The software includes student enrollment, attendance tracking, grade management, fee collection, timetable scheduling, teacher and staff management, and communication tools for parents and teachers. Additionally, it offers analytics and reporting to help administrators make data-driven decisions.",
    },
    {
      trigger: "Is the software accessible on mobile devices?",
      content:
        "Yes, the school management ERP software is fully responsive and accessible on mobile devices. Teachers, students, and parents can easily access the platform through their smartphones or tablets for seamless on-the-go management.",
    },
    {
      trigger: "How secure is the data in the ERP system?",
      content:
        "The software employs advanced security measures, including encryption, role-based access control, and regular backups to ensure that all data is securely stored and protected from unauthorized access.",
    },
    {
      trigger: "Can the software be customized to meet specific needs?",
      content:
        "Absolutely! The school management ERP software is highly customizable. Schools can tailor various modules and features to suit their unique requirements, ensuring the system aligns perfectly with their processes.",
    },
    {
      trigger: "How easy is it to integrate the ERP system with existing school systems?",
      content:
        "The software is designed to integrate seamlessly with various existing systems, such as accounting software and third-party applications. It offers APIs and supports data migration, making the transition smooth and hassle-free.",
    },
  ];
  

  return (
    <div className="w-full mt-10 flex flex-col justify-center items-center">
      <div className="w-[70vw] flex flex-col justify-center items-center text-center gap-3">
        <Heading>
          Best School Management Software With Uncountable Features
        </Heading>
        <Paragraph className={"text-slate-600 w-[20rem] md:w-[50rem]"}>
          Effortlessly manage your school with our all-in-one ERP system.
          Simplify administration, boost efficiency, and enhance communication
          across your institution.
        </Paragraph>
        <div className="mt-7 flex justify-center items-center gap-5 flex-wrap">
          <Button className="w-[10rem] h-[3rem]" asChild>
            <Link to={"/create-new-account"}>Get Started</Link>
          </Button>
          <Button
            className="w-[10rem] h-[3rem] border-slate-300"
            variant="outline"
            asChild
          >
            <Link to={"/signin"}>Login</Link>
          </Button>
        </div>
        <img src={laptop} className="w-[50rem] ml-[6rem] mt-10" alt="" />
        <h2 className="mt-5 text-[30px]">
          Get Your Institution At Your{" "}
          <span className="font-semibold">Fingertips</span> With{" "}
          <span className="font-semibold">15+ Features</span>
        </h2>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#0099ff"
          fill-opacity="1"
          d="M0,192L48,170.7C96,149,192,107,288,80C384,53,480,43,576,80C672,117,768,203,864,197.3C960,192,1056,96,1152,58.7C1248,21,1344,43,1392,53.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
      <div className="w-full text-center bg-[#0099ff] py-20 mt-[-5px]">
        <Heading2 className={"text-white"}>
          Features of Vidyalay School Management Software
        </Heading2>
        <Paragraph className={"text-slate-100 mt-5"}>
          Packed with a wide array of features, our ERP system covers every
          aspect of school management. Experience seamless integration and
          unmatched functionality all in one platform
        </Paragraph>
        <div className="flex flex-col w-full justify-center items-center mt-10 gap-5 text-sky-900">
          <div className="w-full flex justify-center gap-5 flex-wrap">
            <section className="w-[10rem] bg-sky-300 flex flex-col justify-center items-center py-5 rounded-md">
              <BookOpenCheck
                size={"80px"}
                className="text-white p-3 rounded-md"
              />
              <p className="font-medium mt-2">E-Exams</p>
              <p className="text-slate-500 text-[13px] mt-2">
                Schedule and organise exams online
              </p>
            </section>
            <section className="w-[10rem] bg-sky-300 flex flex-col justify-center items-center py-5 rounded-md">
              <Shield size={"80px"} className="text-white p-3 rounded-md" />
              <p className="font-medium mt-2">Secure</p>
              <p className="text-slate-500 text-[13px] mt-2 px-2">
                Complete secure data storing and authentication
              </p>
            </section>
            <section className="w-[10rem] bg-sky-300 flex flex-col justify-center items-center py-5 rounded-md">
              <Bus size={"80px"} className="text-white p-3 rounded-md" />
              <p className="font-medium mt-2">Vehical Tracking</p>
              <p className="text-slate-500 text-[13px] mt-2">
                Manage and track your vehical
              </p>
            </section>
            <section className="w-[10rem] bg-sky-300 flex flex-col justify-center items-center py-5 rounded-md">
              <BellRing size={"80px"} className="text-white p-3 rounded-md" />
              <p className="font-medium mt-2">Push Notification</p>
              <p className="text-slate-500 text-[13px] mt-2">
                Get updated through mobile notification
              </p>
            </section>
          </div>
          <div className="w-full flex justify-center gap-5 flex-wrap">
            <section className="w-[10rem] bg-sky-300 flex flex-col justify-center items-center py-5 rounded-md">
              <CreditCard size={"80px"} className="text-white p-3 rounded-md" />
              <p className="font-medium mt-2">Accounts</p>
              <p className="text-slate-500 text-[13px] mt-2">
                Keep track of all bank accounts
              </p>
            </section>
            <section className="w-[10rem] bg-sky-300 flex flex-col justify-center items-center py-5 rounded-md">
              <DollarSign size={"80px"} className="text-white p-3 rounded-md" />
              <p className="font-medium mt-2">Salary</p>
              <p className="text-slate-500 text-[13px] mt-2 px-2">
                Generate salary recipt and keep track of salaries
              </p>
            </section>
            <section className="w-[10rem] bg-sky-300 flex flex-col justify-center items-center py-5 rounded-md">
              <ShoppingCart
                size={"80px"}
                className="text-white p-3 rounded-md"
              />
              <p className="font-medium mt-2">Shopping</p>
              <p className="text-slate-500 text-[13px] mt-2 px-2">
                Manage all shop expenses here
              </p>
            </section>
            <section className="w-[10rem] bg-sky-300 flex flex-col justify-center items-center py-5 rounded-md">
              <BookType size={"80px"} className="text-white p-3 rounded-md" />
              <p className="font-medium mt-2">Homework</p>
              <p className="text-slate-500 text-[13px] mt-2">
                Manage all classes' homeworks
              </p>
            </section>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center flex-col py-20">
        <Heading2>Why Vidyalay ERP Software?</Heading2>
        <div className="w-full flex justify-center mt-20 gap-[10rem] flex-wrap">
          <section className="w-[30rem]">
            <Heading4>Why Vidyalay Is Best School Management Software</Heading4>
            <Paragraph className={"text-slate-600 mt-5"}>
              Besides, <span className="font-semibold">Vidyalay</span> is a
              completely free online school management software, it has more
              school management features than any other online school management
              system in the market. It does not end here, Vidyalay is still
              enhancing features. You will be automatically updated as a new
              feature will be a part of our free school management software.
              Some main school management features are given below.
            </Paragraph>
          </section>
          <img src={desktop} className="w-[20rem]" alt="" />
        </div>
      </div>
      <div className="w-full flex flex-col justify-center items-center gap-10">
        <div className="w-full flex gap-10 justify-center items-center flex-wrap">
          <div className="w-[30rem]">
            <Heading4>Student Management</Heading4>
            <Paragraph className={"mt-5 text-slate-600"}>
              Manage students effortlessly with our advanced system. Track
              attendance, monitor progress, and manage records in one place.
              Personalized dashboards offer real-time insights, while automated
              grading and reporting save time, ensuring every student excels.
            </Paragraph>
          </div>
          <img src={students} className="w-[25rem]" alt="" />
        </div>
        <div className="w-full flex gap-10 justify-center items-center flex-wrap">
          <img src={androidApp} className="w-[25rem]" alt="" />
          <div className="w-[30rem]">
            <Heading4>
              Manage Your School With Vidyalay Web And Android App
            </Heading4>
            <Paragraph className={"mt-5 text-slate-600"}>
              Access your school's ERP system anytime with our Android app.
              Manage student data, track progress, and stay updated on the go.
              Seamless school management is now just a tap away
            </Paragraph>
          </div>
        </div>
        <div className="w-full flex gap-10 justify-center items-center flex-wrap">
          <div className="w-[30rem]">
            <Heading4>Platform Independent SaaS</Heading4>
            <Paragraph className={"mt-5 text-slate-600"}>
              Manage your institution on any platform with Vidyalay web
              application.
            </Paragraph>
          </div>
          <img src={teamImg} className="w-[25rem]" alt="" />
        </div>
      </div>
      <div className="w-full flex flex-col justify-center items-center bg-[#0099ff] text-white pt-20 mb-[-5px] mt-[5rem]">
        <Heading2>All Features In One Place</Heading2>
        <div className="w-full flex flex-col flex-wrap justify-center items-center mt-10">
          <div className="flex flex-wrap justify-center items-center">
            <section className="text-center flex flex-col justify-center items-center w-[20rem] h-[15rem] px-3 rounded-lg gap-3">
              <HiOutlineBuildingLibrary size={"5rem"} />
              <p className="font-semibold text-[17px]">
                Institution Management
              </p>
              <p className="text-[14px] text-slate-300">
                Manage your institution seamlessly with our ERP system.
                Streamline administration, enhance communication, and boost
                efficiency, all in one powerful platform.
              </p>
            </section>
            <section className="text-center flex flex-col justify-center items-center w-[20rem] h-[15rem] px-3 rounded-lg gap-3">
              <BsBank size={"5rem"} />
              <p className="font-semibold text-[17px]">Bank Management</p>
              <p className="text-[14px] text-slate-300">
                Manage all of your bank accounts of institution and others
                through Vidyalay. Generate bank recipt, pay slip etc from here.
              </p>
            </section>
            <section className="text-center flex flex-col justify-center items-center w-[20rem] h-[15rem] px-3 rounded-lg gap-3">
              <AiOutlineAccountBook size={"5rem"} />
              <p className="font-semibold text-[17px]">Accounts</p>
              <p className="text-[14px] text-slate-300">
                Store and manage your institution's bank account, students' bank
                account, employees' bank account from here without any 3rd party
                intervance.
              </p>
            </section>
          </div>
          <div className="flex flex-wrap justify-center items-center">
            <section className="text-center flex flex-col justify-center items-center w-[20rem] h-[15rem] px-3 rounded-lg gap-3">
              <BsWallet2 size={"5rem"} />
              <p className="font-semibold text-[17px]">Salary Management</p>
              <p className="text-[14px] text-slate-300">
                Manage all of your staffs' salary through the software. Generate
                payment recipt, keep track of all transactions.
              </p>
            </section>
            <section className="text-center flex flex-col justify-center items-center w-[20rem] h-[15rem] px-3 rounded-lg gap-3">
              <IoBookOutline size={"5rem"} />
              <p className="font-semibold text-[17px]">Exam Management</p>
              <p className="text-[14px] text-slate-300">
                Organize exams from here. Manage and control institution's exam
                schedule smartly.
              </p>
            </section>
            <section className="text-center flex flex-col justify-center items-center w-[20rem] h-[15rem] px-3 rounded-lg gap-3">
              <FaPeopleGroup size={"5rem"} />
              <p className="font-semibold text-[17px]">Staff Management</p>
              <p className="text-[14px] text-slate-300">
                Manage your all staffs' attendence, leave requests, salary,
                documents from here. Keep track of your every staffs from
                whereever you want.
              </p>
            </section>
          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#0099ff"
          fill-opacity="1"
          d="M0,128L80,122.7C160,117,320,107,480,133.3C640,160,800,224,960,256C1120,288,1280,288,1360,288L1440,288L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
        ></path>
      </svg>
      <div className="w-full flex flex-col justify-center items-center mt-[5rem] mb-[5rem] bg-center">
        <Heading2>Frequently Asked Questions</Heading2>
        <div className="w-[70vw] flex flex-col justify-center items-center mt-10">
          <AccredionBox dataArray={faqArray} />
        </div>
      </div>
      <div
        className="w-full flex justify-center items-center gap-[5rem] bg-center"
        style={{ backgroundImage: `url(${collegeImg})` }}
      >
        <div className="w-full backdrop-blur-[2px] py-[15rem] bg-[#00000011]">
          <div className="w-full flex gap-10 justify-center flex-wrap">
            <div className="text-center">
              <h3 className="text-[40px] font-extrabold text-white">
                Manage Institution Smartly
              </h3>
              <p className="text-white font-bold text-[20px]">with</p>
              <h2 className="text-[9rem] font-extrabold text-white">
                Vidyalay
              </h2>
              <Paragraph className={"mt-5 text-slate-200"}>
                Access your institution at your fingertips. Manage students,
                staffs, accounts, bank etc all the things with your mobile from
                anywhere in the earth.
              </Paragraph>
              <Button className="mt-5 w-[15rem] h-[3rem] text-[17px]" asChild>
                <Link to={"/create-new-account"}>Create New Account</Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
