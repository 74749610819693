import Heading from "@/components/Heading";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SERVER_LINK } from "@/constant";
import Loading from "@/components/Loading";
import { Button } from "@/components/ui/button";
import useGroup from "@/hook/useGroup";
import { Link } from "react-router-dom";
import { Label } from "@/components/ui/label";
import AlertBox from "@/components/AlertBox";

function PromoteStudents() {
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const institutionId = useSelector(
    (state) => state.auth?.authInfo?.institutionId
  );
  const [group, setGroup] = useState({});
  const [groupKeys, setGroupKeys] = useState([]);
  const [resetFilter, setResetFilter] = useState(false);
  const [allPromoted, setAllPromoted] = useState(false);
  const [promotedList, setPromotedList] = useState([]);
  const [promotedTo, setPromotedTo] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function dateToString(str) {
    const date = new Date(str);
    let newDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      hour: "2-digit",
      month: "short",
      year: "numeric",
      minute: "2-digit",
    });
    return newDate;
  }

  async function getStudents() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/student/getstudents?institutionId=${institutionId}`,
        method: "get",
      });
      const data = res.data;
      data.map((e) => {
        e.admissionDate = dateToString(e.admissionDate);
        e.dateOfBirth = dateToString(e.dateOfBirth);
      });
      setStudents(data);
      const group = useGroup(data, "class_");
      setGroup(group["obj"]);
      setGroupKeys(group["keys"]);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Stdeunt fetch failed");
    } finally {
      setLoading(false);
    }
  }

  function makePromotedList(event) {
    const { value, checked } = event.target;
    if (checked) {
      setPromotedList((prev) => [...prev, value]);
    } else {
      const index = promotedList.indexOf(value);
      setPromotedList((prev) => [
        ...prev.slice(0, index),
        ...prev.slice(index + 1),
      ]);
    }
  }

  let list = [];
  if (allPromoted) {
    students.map((e) => {
      list.push(e.registrationNumber);
    });
  }

  async function sendPromotedList() {
    let rawlist = [];
    if (list.length > 0) {
      rawlist = list;
    } else {
      rawlist = promotedList;
    }
    let finalList = rawlist.map(e => {
        const obj = {
            registrationNumber: e
        }
        return obj
    })
    let data = {
      institutionId,
      className: promotedTo,
      promoteStudentsList: finalList,
    };

    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/promote-students/promote`,
        method: "post",
        data: data
      });
      setOpen(true);
      setSuccess(true);
      setErrorMessage("Students are promoted");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Student promotion failed");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getStudents();
  }, [resetFilter, setResetFilter]);

  function filterData(query) {
    if (query.length > 0) {
      setStudents(group[query]);
    }
  }

  if (loading) {
    return <Loading color={"bg-sky-500"} />;
  }

  return (
    <div className="w-full px-10 py-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={"Sign Up Failed"}
          desc={errorMessage}
        />
      )}
      <Heading>Promote Students</Heading>
      <div className="w-full flex items-end justify-between mt-5">
        <section className="text-[12px] flex items-center gap-4 border-[1px] px-2 py-1 rounded-md">
          <div className="flex items-center gap-2">
            <section className="w-[7px] h-[7px] bg-green-500 rounded-full"></section>{" "}
            means <span className="text-green-500 font-medium">active</span>
          </div>
          <div className="flex items-center gap-2">
            <section className="w-[7px] h-[7px] bg-red-500 rounded-full"></section>{" "}
            means <span className="text-red-500 font-medium">inactive</span>
          </div>
        </section>
        <Button asChild variant="outline">
          <Link to={"/add-students"}>Add new student</Link>
        </Button>
      </div>
      <div className="w-full flex flex-col gap-10 justify-center items-center">
        <div className="mt-5 border-[1px] w-full flex flex-col items-center gap-5 px-7 py-5 rounded-lg">
          <section className="flex gap-[5rem] items-center">
            <div className="flex items-center gap-2">
              <select
                onChange={(e) => filterData(e.target.value)}
                className="w-[10rem] px-3 py-2 bg-white border-[1px] rounded-md text-[14px]"
              >
                <option value={""}>Select A Class</option>
                {groupKeys.map((e) =>
                  e.length > 0 ? <option value={e}>{e}</option> : null
                )}
              </select>
              <Button
                variant="ghost"
                onClick={() => setResetFilter((prev) => !prev)}
              >
                Reset
              </Button>
            </div>
            <div className="flex items-center gap-2">
              <select
                onChange={(e) => setPromotedTo(e.target.value)}
                className="w-[10rem] px-3 py-2 bg-white border-[1px] rounded-md text-[14px]"
              >
                <option value={""}>Promoted To</option>
                {groupKeys.map((e) =>
                  e.length > 0 ? <option value={e}>{e}</option> : null
                )}
              </select>
              <Button
                variant="ghost"
                onClick={() => setResetFilter((prev) => !prev)}
              >
                Save
              </Button>
            </div>
            <div className="space-x-2">
              <input
                type="checkbox"
                onChange={() => setAllPromoted((prev) => !prev)}
              />
              <Label>Select All</Label>
            </div>
          </section>
          <Table>
            <TableCaption>All students list</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="text-center">Name</TableHead>
                <TableHead className="text-center">Registration No</TableHead>
                <TableHead className="text-center">Date Of Birth</TableHead>
                <TableHead className="text-center">Class</TableHead>
                <TableHead className="text-center">Admission Date</TableHead>
                <TableHead className="text-center">Options</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {students.map((s, i) => (
                <TableRow key={i}>
                  <TableCell className="text-center flex justify-center items-center gap-3">
                    {s.status === "active" ? (
                      <section className="w-[10px] h-[10px] bg-green-500 rounded-full"></section>
                    ) : (
                      <section className="w-[10px] h-[10px] bg-red-500 rounded-full"></section>
                    )}
                    {s.name}
                  </TableCell>
                  <TableCell className="text-center">
                    {s.registrationNumber}
                  </TableCell>
                  <TableCell className="text-center">{s.dateOfBirth}</TableCell>
                  <TableCell className="text-center">{s.class_}</TableCell>
                  <TableCell className="text-center">
                    {s.admissionDate}
                  </TableCell>
                  <TableCell className="text-center">
                    <input
                      type="checkbox"
                      onChange={makePromotedList}
                      value={s.registrationNumber}
                      checked={
                        allPromoted ||
                        promotedList.includes(s.registrationNumber)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Button onClick={sendPromotedList}>Promote</Button>
      </div>
    </div>
  );
}

export default PromoteStudents;
