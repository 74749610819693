import Heading from "@/components/Heading";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "@/components/InputField";
import Loading from "@/components/Loading";
import { useSelector } from "react-redux";
import { Button } from "@/components/ui/button";
import axios from "axios";
import AlertBox from "@/components/AlertBox";
import { SERVER_LINK } from "@/constant";
import Heading2 from "@/components/Heading2";

function AddEmployee() {
  const { register: registerBasic, handleSubmit: handleBasic } = useForm();
  const { register: registerBank, handleSubmit: handleBankSubmit } = useForm();
  const {
    register: registerOfferLetter,
    handleSubmit: handleOfferLetterSubmit,
  } = useForm();

  const institutionId = useSelector(
    (state) => state.auth?.authInfo?.institutionId
  );
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [defaultData, setDefaultData] = useState(null);
  const accessToken = useSelector((state) => state.auth?.authInfo?.accessToken);
  const [empId, setEmpId] = useState("");
  const updateData = useSelector((state) => state.updateData.updateData);
  const [enableBankDetails, setEnableBankDetails] = useState(false);
  const [enableBasicDetails, setEnableBasicDetails] = useState(false);
  const [enableOfferDetails, setEnableOfferDetails] = useState(false);

  async function getBankDetails(empId) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/employee/bankDetails?employeeId=${empId}`,
        method: "get",
      });
      //console.log(res);
      setDefaultData((prev) => {
        return { ...prev, ...res.data };
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (updateData) {
      setDefaultData(updateData);
      getBankDetails(updateData.employeeId);
    }
  }, [updateData]);

  async function addEmployee(data) {
    //Generating employee id

    let random4digit = Math.floor(Math.random() * (9999 - 999) + 999);
    let empId = `${data.name?.slice(0, 4)}${institutionId.slice(
      0,
      3
    )}${random4digit}`;

    setEmpId(empId);
    data = { ...data, employeeId: empId };
    if (data.institutionId.length === 0) {
      data.institutionId = institutionId;
    }

    if (updateData) {
      const fields = Object.keys(data);
      fields.map((e) => {
        if (data[e].length === 0) {
          data[e] = updateData[e];
        }
      });
    }

    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/employee/create`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        method: "post",
        data: data,
      });
      //console.log(res);
      if (res.status >= 200 || res.status < 400) {
        setOpen(true);
        setSuccess(true);
        setErrorMessage("Employee added Successfully");
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage(error?.response?.data?.error?.message);
    } finally {
      setLoading(false);
    }
  }

  console.log(defaultData);

  async function bankDetails(data) {
    data = { ...data, employeeId: defaultData?.employeeId || empId };
    data = { ...data, instituteId: institutionId };
    if (updateData) {
      const fields = Object.keys(data);
      console.log(fields);
      fields.map((e) => {
        if (data[e].length === 0) {
          data[e] = defaultData[e];
        }
      });
    }
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/employee/registerBankDetails`,
        method: "post",
        data: data,
      });
      //console.log(res);
      if (res.status >= 200 || res.status < 400) {
        setOpen(true);
        setSuccess(true);
        setErrorMessage("Employee  bank details added Successfully");
      }
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Employee bank details update failed");
    } finally {
      setLoading(false);
    }
  }

  async function getOfferLetter(data) {
    data = { ...data, employeeId: defaultData?.employeeId || empId, institutionId };
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/offerletter/postOfferLetter`,
        method: "post",
        data: data,
      });
      //console.log(res);
      if (res.status >= 200 || res.status < 400) {
        setOpen(true);
        setSuccess(true);
        setErrorMessage("Offer letter added Successfully");
      }
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Offer letter update failed");
    } finally {
      setLoading(false);
    }
  }

  console.log(defaultData?.employeeId);

  return (
    <div className="w-full px-10 py-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <Heading>Add Employee</Heading>
      <div className="w-full flex flex-wrap justify-center">
        <form
          className="w-full flex flex-col gap-10 items-center justify-center"
          onSubmit={handleBasic(addEmployee)}
          onChange={() => setEnableBasicDetails(true)}
        >
          <div className="bg-sky-400 w-full px-3 py-2">
            <Heading2 className={"text-white"}>Basic Information</Heading2>
          </div>
          <div className="w-full flex flex-wrap gap-10 mt-5 items-center justify-center">
            <InputField
              label="Institution ID"
              type="text"
              value={institutionId}
              {...registerBasic("institutionId")}
            />
            <InputField
              label="Name"
              type="text"
              defaultValue={defaultData?.name}
              {...registerBasic("name")}
            />
            <InputField
              label="Joining Date"
              type="date"
              defaultValue={defaultData?.joiningDate}
              {...registerBasic("joiningDate")}
            />
            <InputField
              label="Employee Type"
              type="text"
              defaultValue={defaultData?.employeeType}
              {...registerBasic("employeeType")}
            />
            <InputField
              label="Monthly Salary"
              type="text"
              defaultValue={defaultData?.monthlySalary}
              {...registerBasic("monthlySalary")}
            />
            <InputField
              label="Father Name"
              type="text"
              defaultValue={defaultData?.fatherName}
              {...registerBasic("fatherName")}
            />
            <InputField
              label="Mobile Number"
              type="text"
              defaultValue={defaultData?.mobileNumber}
              {...registerBasic("mobileNumber")}
            />
            <InputField
              label="Education"
              type="text"
              defaultValue={defaultData?.education}
              {...registerBasic("education")}
            />
            <InputField
              label="Gender"
              type="text"
              defaultValue={defaultData?.gender}
              {...registerBasic("gender")}
            />
            <InputField
              label="Religion"
              type="text"
              defaultValue={defaultData?.religion}
              {...registerBasic("religion")}
            />
            <InputField
              label="Blood Group"
              type="text"
              defaultValue={defaultData?.bloodGroup}
              {...registerBasic("bloodGroup")}
            />
            <InputField
              label="Date of Birth"
              type="date"
              defaultValue={defaultData?.dateOfBirth}
              {...registerBasic("dateOfBirth")}
            />
            <InputField
              label="Experience"
              type="text"
              defaultValue={defaultData?.experience}
              {...registerBasic("experience")}
            />
            <InputField
              label="Email"
              type="text"
              defaultValue={defaultData?.email}
              {...registerBasic("email")}
            />
            <InputField
              label="Address"
              type="text"
              defaultValue={defaultData?.address}
              {...registerBasic("address")}
            />
          </div>
          {enableBasicDetails === false ? (
            <Button disabled>Update</Button>
          ) : (
            <Button className="w-[10rem]" type="submit">
              {loading === true ? <Loading color={"bg-white"} /> : "Update"}
            </Button>
          )}
        </form>
        <form
          className="w-full flex flex-col gap-10 items-center justify-center mt-10"
          onSubmit={handleBankSubmit(bankDetails)}
          onChange={() => setEnableBankDetails(true)}
        >
          <div className="bg-sky-400 w-full px-3 py-2">
            <Heading2 className={"text-white"}>Bank Details</Heading2>
          </div>
          <div className="w-full flex flex-wrap gap-10 mt-5 items-center justify-center">
            <InputField
              label="Bank Name"
              type="text"
              defaultValue={defaultData?.bankName}
              {...registerBank("bankName")}
            />
            <InputField
              label="Bank Account Number"
              type="text"
              defaultValue={defaultData?.accountNumber}
              {...registerBank("accountNumber")}
            />
            <InputField
              label="IFS Code"
              type="text"
              defaultValue={defaultData?.ifsCode}
              {...registerBank("ifsCode")}
            />
            <InputField
              label="PAN Number"
              type="text"
              defaultValue={defaultData?.panNumber}
              {...registerBank("panNumber")}
            />
            <InputField
              label="PF Number"
              type="text"
              defaultValue={defaultData?.pfNo}
              {...registerBank("pfNo")}
            />
            <InputField
              label="PF UAN"
              type="text"
              {...registerBank("pfUAN")}
              defaultValue={defaultData?.pfUAN}
            />
          </div>
          {enableBankDetails === true ? (
            <Button className="w-[10rem]" type="submit">
              {loading === true ? <Loading color={"bg-white"} /> : "Update"}
            </Button>
          ) : (
            <Button disabled>Update</Button>
          )}
        </form>
        <form
          className="w-full flex flex-col gap-10 items-center justify-center mt-10"
          onSubmit={handleOfferLetterSubmit(getOfferLetter)}
          onChange={() => setEnableOfferDetails(true)}
        >
          <div className="bg-sky-400 w-full px-3 py-2">
            <Heading2 className={"text-white"}>Offer Letter Details</Heading2>
          </div>
          <div className="w-full flex flex-wrap gap-10 mt-5 items-center justify-center">
            <InputField
              label="Background Format URL"
              type="text"
              {...registerBank("backgroundFormatURL")}
            />
            <InputField
              label="Employee Name"
              type="text"
              {...registerOfferLetter("emoloyeename")}
            />
            <InputField
              label="Address"
              type="text"
              {...registerOfferLetter("address")}
            />
            <InputField
              label="Basic"
              type="text"
              {...registerOfferLetter("basic")}
            />
            <InputField
              label="Date"
              type="date"
              {...registerOfferLetter("date")}
            />
            <InputField
              label="Employee Contribution Provident Fund"
              type="text"
              {...registerOfferLetter("empContPF")}
            />
            <InputField
              label="Employeer Name"
              type="text"
              {...registerOfferLetter("employerName")}
            />
            <InputField
              label="Employer Position"
              type="text"
              {...registerOfferLetter("employerPosition")}
            />
            <InputField
              label="Fixed CTC"
              type="text"
              {...registerOfferLetter("fixedCTC")}
            />
            <InputField
              label="Food Coupons"
              type="text"
              {...registerOfferLetter("foodCoupons")}
            />
            <InputField
              label="House Rent Allowence"
              type="text"
              {...registerOfferLetter("hra")}
            />
            <InputField
              label="Institution Name"
              type="text"
              {...registerOfferLetter("institutionName")}
            />
            <InputField
              label="Joining Date"
              type="date"
              {...registerOfferLetter("joiningDate")}
            />
            <InputField
              label="Leave Travel Allowence"
              type="text"
              {...registerOfferLetter("lta")}
            />
            <InputField
              label="Minimum Basic"
              type="text"
              {...registerOfferLetter("mbasic")}
            />
            <InputField
              label="Medical Insurance Coverage"
              type="text"
              {...registerOfferLetter("mInsCoverage")}
            />
            <InputField
              label="Medical Reimbursement"
              type="text"
              {...registerOfferLetter("medicalReimbursement")}
            />

            <InputField
              label="Monthly Employee Contribution To Provident Fund"
              type="text"
              {...registerOfferLetter("mempContPF")}
            />
            <InputField
              label="Monthly Fixed CTC"
              type="text"
              {...registerOfferLetter("mfixedCTC")}
            />
            <InputField
              label="Monthly Food Coupons"
              type="text"
              {...registerOfferLetter("mfoodCoupons")}
            />
            <InputField
              label="Monthly House Rent Allowence"
              type="text"
              {...registerOfferLetter("mhra")}
            />
            <InputField
              label="Monthly Leave Travel Experience"
              type="text"
              {...registerOfferLetter("mlta")}
            />
            <InputField
              label="Monthly Medical Insurance Coverage"
              type="text"
              {...registerOfferLetter("mmedInsCoverage")}
            />
            <InputField
              label="Monthly Medical Reimbursement"
              type="text"
              {...registerOfferLetter("mmedicalReimbursement")}
            />
            <InputField
              label="Monthly Special Allowence"
              type="text"
              {...registerOfferLetter("mspecialAllowence")}
            />
            <InputField
              label="Monthly Transport Allowence"
              type="text"
              {...registerOfferLetter("mtransportAllowance")}
            />
            <InputField
              label="Employee Position"
              type="text"
              {...registerOfferLetter("position")}
            />
            <InputField
              label="Report Date"
              type="date"
              {...registerOfferLetter("reportDate")}
            />
            <InputField
              label="Report Time"
              type="time"
              {...registerOfferLetter("reportTime")}
            />
            <InputField
              label="Signature"
              type="text"
              {...registerOfferLetter("signature")}
            />
            <InputField
              label="Special Allowence"
              type="text"
              {...registerOfferLetter("specialAllowence")}
            />
            <InputField
              label="Total"
              type="text"
              {...registerOfferLetter("total")}
            />
            <InputField
              label="Total In Words"
              type="text"
              {...registerOfferLetter("totalInWords")}
            />
            <InputField
              label="Transport Allowence"
              type="text"
              {...registerOfferLetter("transportAllowence")}
            />
            <InputField
              label="Working Hours"
              type="text"
              {...registerOfferLetter("workingHours")}
            />
          </div>
          {enableOfferDetails === true ? (
            <Button className="w-[10rem]" type="submit">
              {loading === true ? (
                <Loading color={"bg-white"} />
              ) : (
                "Get Offer Letter"
              )}
            </Button>
          ) : (
            <Button disabled>Get Offer Letter</Button>
          )}
        </form>
      </div>
    </div>
  );
}

export default AddEmployee;
