import Heading from "@/components/Heading";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { SERVER_LINK } from "@/constant";
import DialogBox from "@/components/DialogBox";
import TableBox from "@/components/TableBox";
import { Button } from "@/components/ui/button";
import AlertBox from "@/components/AlertBox";
import InputField from "@/components/InputField";
import { useForm } from "react-hook-form";

function AccountStatement() {
  const [loading, setLoading] = useState(false);
  const institutionId = useSelector(
    (state) => state?.auth?.authInfo?.institutionId
  );
  const [defaultData, setDefaultData] = useState([]);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [mainData, setMainData] = useState(null);
  const { register, handleSubmit } = useForm();
  const [enableButton, setEnableButton] = useState(false);

  function dateToString(str) {
    const date = new Date(str);
    let newDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "2-digit",
      year: "numeric",
    });
    return newDate;
  }

  async function updateStatement(data, type, headName, date, amount, id) {
    data = { ...data, type, description: headName, institutionId, id };
    if (!data.date) {
      data = { ...data, date };
    }
    if (!data.amount) {
      data = { ...data, amount };
    }
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/chartofaccounts/updateAccountStatement`,
        method: "post",
        data: data,
      });
      setReload((prev) => !prev);
      setOpen(true);
      setSuccess(true);
      setErrorMessage("Account statement has been added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Account statement add failed");
    } finally {
      setLoading(false);
    }
  }

  //Data filteration by date

  function filterData() {
    let filteredData = [];
    const fromDate = new Date(from);
    const toDate = new Date(to);

    mainData.map((e) => {
      const newDate = new Date(e.date);
      if (newDate >= fromDate && newDate <= toDate) {
        filteredData.push(e);
      }
    });

    setDefaultData(filteredData);
  }

  function printStatement() {
    window.location = `${SERVER_LINK}/chartofaccounts/accountStatement?institutionId=${institutionId}`;
  }

  const fieldArray = [
    <p className="text-left font-medium">Name</p>,
    "Type",
    "Date",
    "Amount",
    <p className="text-right font-medium">Update</p>,
  ];

  async function getAccountData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/chartofaccounts/getAccountStatement?institutionId=${institutionId}`,
        method: "get",
      });
      const data = res.data;
      data.map((e) => {
        e.date = dateToString(e.date);
      });
      //console.log(data);
      setDefaultData(data);
      setMainData(data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Account data fetch failed");
    } finally {
      setLoading(false);
    }
  }

  const valueArray = defaultData.map((e) => {
    const value = {
      name: <p className="text-left">{e?.description}</p>,
      type: e?.type,
      date: e?.date,
      amount: e?.amount,
      update: (
        <div className="flex justify-end">
          <DialogBox
            title={"Update"}
            contentTitle={`Update Statement of ${e?.description}`}
          >
            <form
              onSubmit={handleSubmit((data) =>
                updateStatement(
                  data,
                  e?.type,
                  e?.description,
                  e?.date,
                  e?.amount,
                  e?._id
                )
              )}
              className="space-y-5"
              onChange={() => setEnableButton(true)}
            >
              <InputField label="Date" type="date" {...register("date")} />
              <InputField label="Amount" type="text" {...register("amount")} />
              {enableButton === true ? (
                <Button>Submit</Button>
              ) : (
                <Button disabled>Submit</Button>
              )}
            </form>
          </DialogBox>
        </div>
      ),
    };

    return value;
  });

  useEffect(() => {
    getAccountData();
  }, [reload]);

  return (
    <div className="w-full px-10 py-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <Heading>Account Statement</Heading>
      <div className="w-full flex flex-col gap-5 justify-center items-center">
        <div className="w-full p-10 border-[1px]">
          <div className="w-full flex justify-center mb-10 gap-10 items-center">
            <input
              type="date"
              className="border-[1px] p-3 rounded-md"
              onChange={(e) => setFrom(e.target.value)}
            />
            <p>To</p>
            <input
              type="date"
              className="border-[1px] p-3 rounded-md"
              onChange={(e) => setTo(e.target.value)}
            />
            <Button
              variant="ghost"
              onClick={() => {
                filterData();
              }}
            >
              Filter
            </Button>
            <Button
              variant="ghost"
              onClick={() => {
                setDefaultData(mainData);
              }}
            >
              Reset
            </Button>
          </div>
          <TableBox fieldArray={fieldArray} dataArray={valueArray} />
        </div>
        <Button variant="outline" onClick={printStatement}>
          Print Statement
        </Button>
      </div>
    </div>
  );
}

export default AccountStatement;
