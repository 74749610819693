import React, { useState } from "react";
import user from "../assets/user.svg";
import Heading4 from "@/components/Heading4";
import Paragraph from "@/components/Paragraph";
import { IoCall } from "react-icons/io5";
import { Button } from "@/components/ui/button";
import DialogBoxWithAPI from "./DialogBoxWithAPI";
import { DropDownMenu } from "./DropDownMenu";
import { useDispatch } from "react-redux";
import { setUpdateData } from "@/store/updateSlice";
import { Link, useNavigate } from "react-router-dom";
import { DropdownMenuItem } from "./ui/dropdown-menu";
import { SERVER_LINK } from "@/constant";
import DialogBox from "./DialogBox";
import { useForm } from "react-hook-form";
import InputField from "./InputField";
import AlertBox from "./AlertBox";
import axios from "axios";

function PersonCard({ data, setReload }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  function editEmpData() {
    dispatch(setUpdateData({ updateData: data }));
    navigate("/add-employee");
  }

  function getIdCard() {
    const empId = data.employeeId;
    const institutionId = data.institutionId;
    console.log(empId);
    console.log(institutionId);
    //navigate(`${SERVER_LINK}/idCard/generateEmployeeID?employeeId=${empId}&institutionId=${institutionId}`)
    console.log(
      `${SERVER_LINK}/idCard/generateEmployeeID?employeeId=${empId}&institutionId=${institutionId}`
    );
  }

  async function updatePassword(updateData) {
    if (updateData.newPassword != updateData.confirmPassword) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Password does not match");
      return;
    }
    const finalData = {
      mobileNumber: data.mobileNumber,
      newPassword: updateData.newPassword,
    };
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/staff/updatePasswordOfStaff`,
        method: "post",
        data: finalData,
      });
      setOpen(true);
      setSuccess(true);
      setErrorMessage("Password has been changed");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Password change failed");
    } finally {
      setLoading(false);
    }
  }

  async function updateStatus(status) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/employee/update-status`,
        method: "post",
        data: {
          employeeId: data.employeeId,
          status,
        },
      });
      setReload(prev => !prev)
      setOpen(true);
      setSuccess(true);
      setErrorMessage("Status has been changed");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Status change failed");
    } finally {
      setLoading(false);
    }
  }

  return (
    <section className="border-[1px] w-[auto] h-[auto] flex flex-col items-center p-3 rounded-lg">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <div className="flex justify-between w-full">
        <img
          src={user}
          className="w-[7rem] h-[7rem] object-cover rounded-full"
          alt=""
        />
        <DropDownMenu>
          <DropdownMenuItem className="">
            <p onClick={editEmpData} className="font-medium cursor-pointer">
              Edit Details
            </p>
          </DropdownMenuItem>
          <DropdownMenuItem className="">
            <Link
              className="w-full font-medium"
              to={`${SERVER_LINK}/offerletter/getOfferLetter?employeeId=${data.employeeId}&institutionId=${data.institutionId}`}
            >
              Offer Letter
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem className="">
            <Link className="w-full font-medium" onClick={getIdCard}>
              ID Card
            </Link>
          </DropdownMenuItem>
        </DropDownMenu>
      </div>
      <div className="w-full text-left mt-3">
        <Heading4>{data.name}</Heading4>
        <section className="flex justify-between text-[13px] mt-1">
          <div>
            <Paragraph className={"text-slate-600 font-medium"}>
              {data.employeeType}
            </Paragraph>
          </div>
          <section
            className="flex items-center gap-1 h-[20px] bg-sky-100 py-[2px] px-[6px] rounded-md text-[12px] cursor-pointer"
            onClick={() => {
              data.status === "active"
                ? updateStatus("inactive")
                : updateStatus("active");
            }}
          >
            <p className="text-slate-600">
              {data.status === "active" ? "Active" : "Inactive"}
            </p>
            <div
              className={`w-[10px] h-[10px] ${
                data.status === "active" ? "bg-green-500" : "bg-red-500"
              } rounded-full`}
            ></div>
          </section>
        </section>
        <section className="flex justify-between text-[13px] mt-1">
          <div>
            <Paragraph
              className={"text-slate-600 font-medium flex items-center gap-1"}
            >
              <IoCall
                size={"17px"}
                className="p-[3px] bg-sky-500 text-white rounded-full"
              />
              <p>{data.mobileNumber}</p>
            </Paragraph>
          </div>
          <section className="flex items-center gap-1 h-[20px] text-[12px]">
            <p className="text-slate-600 font-medium">Job ID •</p>
            <p className="text-slate-600">{data.employeeId}</p>
          </section>
        </section>
        <div className="flex gap-5 justify-between mt-3">
          <DialogBoxWithAPI empId={data.employeeId} />
          <DialogBox title={"Update Password"}>
            {success && (
              <AlertBox
                open={open}
                setOpen={setOpen}
                success={success}
                title={errorMessage}
              />
            )}
            {!success && (
              <AlertBox
                open={open}
                setOpen={setOpen}
                success={success}
                title={errorMessage}
              />
            )}
            <form
              className="space-y-5 mt-5 flex flex-col items-end"
              onSubmit={handleSubmit(updatePassword)}
            >
              <InputField
                label="New Password"
                type="password"
                {...register("newPassword")}
              />
              <InputField
                label="Confirm Password"
                type="password"
                {...register("confirmPassword")}
              />
              <Button type="submit">Update</Button>
            </form>
          </DialogBox>
        </div>
      </div>
    </section>
  );
}

export default PersonCard;
