import Heading from "@/components/Heading";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { SERVER_LINK } from "@/constant";
import DataTable from "@/components/DataTable";
import Loading from "@/components/Loading";
import DialogBox from "@/components/DialogBox";
import { FaRegPlusSquare } from "react-icons/fa";
import InputField from "@/components/InputField";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import AlertBox from "@/components/AlertBox";

function Classes() {
  const institutionId = useSelector(
    (state) => state.auth?.authInfo?.institutionId
  );
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [reload, setReload] = useState(false);
  const { register, handleSubmit } = useForm();
  const [enableButton, setEnableButton] = useState();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function getClasses() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/class/getclasses?institutionId=${institutionId}`,
        method: "get",
      });
      //console.log(res.data);
      setDefaultValues(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getTeacher() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/employee/getemployees?institutionId=${institutionId}`,
        method: "get",
      });
      //console.log(res.data);
      const employees = res.data;
      const teachers = employees.filter((e) => {
        if (e?.employeeType === "Teacher" || e?.employeeType === "teacher") {
          return e;
        }
      });
      //console.log(teachers);
      setTeachers(teachers);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getClasses();
    getTeacher();
  }, [reload]);

  async function createClass(data) {
    if (data.classTeacher === "Teacher") {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Please select a teacher");
    }

    const selectedTeacher = data.classTeacher;
    const splitedTeacher = selectedTeacher.split("#");
    data.classTeacher = splitedTeacher[0].trim();
    data = {
      ...data,
      classTeacherId: splitedTeacher[1],
      institutionID: institutionId,
    };
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/class/create`,
        method: "post",
        data: data,
      });
      //console.log(res);
      if (res.status >= 200 || res.status < 400) {
        setReload((prev) => !prev);
        setOpen(true);
        setSuccess(true);
        setErrorMessage("Update Successfull");
      }
    } catch (error) {
      console.log(error);
      setOpen(true);
      setSuccess(false);
      setErrorMessage(
        error?.response?.data?.error?.message || "Something went wrong"
      );
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div>
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  return (
    <div className="w-full px-10 py-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <Heading>Classes</Heading>
      <div className="w-full flex flex-col items-end gap-10">
        <DialogBox
          title={"Create new class"}
          icon={<FaRegPlusSquare size={"16px"} />}
          buttonClass={"flex items-center gap-2"}
        >
          <form
            onSubmit={handleSubmit(createClass)}
            className="w-full flex flex-col gap-5"
            onChange={() => setEnableButton(true)}
          >
            <InputField label="Class Name" {...register("className")} />
            <section className="w-full flex flex-col gap-3">
              <Label>Class Teacher</Label>
              <select
                {...register("classTeacher")}
                className="py-3 px-3 rounded-sm bg-white border-[1px]"
              >
                <option>Teacher</option>
                {teachers &&
                  teachers.map((e) => (
                    <option value={`${e.name}#${e.employeeId}`}>
                      {e.name}
                    </option>
                  ))}
              </select>
            </section>
            <InputField
              label="Monthly Fees"
              {...register("monthlyTuitionFee")}
            />
            {enableButton ? (
              <Button>Update</Button>
            ) : (
              <Button disabled>Update</Button>
            )}
          </form>
        </DialogBox>
        <DataTable
          defaultValues={defaultValues}
          teachers={teachers}
          setReload={setReload}
        />
      </div>
    </div>
  );
}

export default Classes;
