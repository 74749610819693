import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

function PieChart({ param, dataset, label }) {

    Chart.register(...registerables)

  const colors = [
    "#1D5D9B", // Blue
    "#75C2F6",
  ];

  const data = {
    labels: param,
    datasets: [
      {
        label: label,
        data: dataset,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRation: 2,
    plugins: {
      title: {
        display: true,
        text: "",
        font: {
          size: 20,
        },
      },
      legend: {
        display: false,
        position: "right",
      },
      tooltip: {
        mode: "index",
        intersect: true,
      },
    },
    scales: {
      x: {
        display: false,
        title: {
          display: false,
          text: "Year",
        },
      },
      y: {
        display: false,
        title: {
          display: false,
          text: "Intensity",
        },
        suggestedMin: 0,
        suggestedMax: 100,
      },
    },
    elements: {
      point: {
        radius: 5,
        borderWidth: 7,
        backgroundColor: "rgba(255, 99, 132, 1)",
        borderColor: "rgba(255, 99, 132, 1)",
      },
      line: {
        tension: 0.4,
        borderWidth: 6,
        borderColor: "#0E46A3",
        backgroundColor: "#0E46A3",
      },
    },
  };

  return (
    <div className="w-full">
      <Doughnut data={data} options={options} />
    </div>
  );
}

export default PieChart;
