import React from "react";

function Heading({children, className}) {
  return (
    <h1 className={`${className} scroll-m-20 text-4xl text-center mb-5 font-extrabold tracking-tight lg:text-5xl`}>
      {children}
    </h1>
  );
}

export default Heading;
