import React, {useState, useEffect} from 'react'
import { useForm } from 'react-hook-form';
import InputField from './InputField';
import axios from 'axios';
import { SERVER_LINK } from '@/constant';
import Loading from './Loading';
import AlertBox from './AlertBox';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "@/components/ui/dialog";
import { Button } from './ui/button';


function UpdateDialogBox({values, teachers, setReload}) {
    const { register, handleSubmit } = useForm();
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [enableButton, setEnableButton] = useState(false);
    const [loading, setLoading] = useState(false);
    //console.log(values);
  
    async function updateClass(data) {
      if (data.classTeacher === "Teacher") {
        setOpen(true);
        setSuccess(false);
        setErrorMessage("Please select a teacher");
      }
  
      const selectedTeacher = data.classTeacher;
      const splitedTeacher = selectedTeacher.split("#");
      data.classTeacher = splitedTeacher[0];
      data = {
        ...data,
        classTeacherId: splitedTeacher[1],
        classId: values.classID,
        className: values.className,
      };
      console.log(data);
  
      try {
        setLoading(true);
        const res = await axios({
          url: `${SERVER_LINK}/class/updateclass`,
          method: "post",
          data: data,
        });
        //console.log(res);
        if (res.status >= 200 || res.status < 400) {
          setReload((prev) => !prev);
          setOpen(true);
          setSuccess(true);
          setErrorMessage("Update Successfull");
        }
      } catch (error) {
        console.log(error);
        setOpen(true);
        setSuccess(false);
        setErrorMessage(
          error?.response?.data?.error?.message || "Something went wrong"
        );
      } finally {
        setLoading(false);
      }
    }
  
    if (loading) {
      return <Loading color={"bg-sky-500"} />;
    }
  
    return (
      <Dialog>
        <DialogTrigger asChild>
          <Button>Update</Button>
        </DialogTrigger>
        <DialogContent className={`sm:max-w-[420px] h-[auto] overflow-auto`}>
          {success && (
            <AlertBox
              open={open}
              setOpen={setOpen}
              success={success}
              title={errorMessage}
            />
          )}
          {!success && (
            <AlertBox
              open={open}
              setOpen={setOpen}
              success={success}
              title={errorMessage}
            />
          )}
          <DialogHeader>
            <DialogTitle>Update {values.className}</DialogTitle>
            <DialogDescription>Update the class details</DialogDescription>
          </DialogHeader>
          <form
            onSubmit={handleSubmit(updateClass)}
            className="w-full flex flex-col gap-5"
            onChange={() => setEnableButton(true)}
          >
            <p className="text-slate-900 text-[15px]">
              Present Teacher <br />
              <span className="text-slate-600">{values.classTeacher}</span>
            </p>
            <select
              {...register("classTeacher")}
              className="py-3 px-3 rounded-sm bg-white border-[1px]"
            >
              <option>Teacher</option>
              {teachers &&
                teachers.map((e,i) => (
                  <option value={`${e.name}#${e.employeeId}`} key={i}>{e.name}</option>
                ))}
            </select>
            <InputField
              label="Monthly Fees"
              defaultValue={values.monthlyTuitionFee}
              {...register("monthlyTuitionFee")}
            />
            {enableButton ? (
              <Button>Update</Button>
            ) : (
              <Button disabled>Update</Button>
            )}
          </form>
        </DialogContent>
      </Dialog>
    );
  }

export default UpdateDialogBox