import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

function AccredionBox({ dataArray }) {

    console.log(dataArray);

  return (
    <Accordion type="single" collapsible className="w-full space-y-3 text-sky-900">
      {dataArray.map((e, i) => (
        <AccordionItem value={`item-${i}`} key={i} className="bg-sky-200 rounded-2xl px-3 hover:bg-sky-500 hover:text-white">
          <AccordionTrigger>{e.trigger}</AccordionTrigger>
          <AccordionContent>
            {e.content}
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default AccredionBox;
