import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import otpSlice from "./otpSlice";
import updateSlice from "./updateSlice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        otpVerify : otpSlice,
        updateData : updateSlice
    }
})

export default store