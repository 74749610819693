import React, {useState, useEffect} from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import axios from "axios";
import { SERVER_LINK } from "@/constant";

function DialogBoxWithAPI({ empId }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  async function getEmplopyeeDetails() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/employee/id?employeeId=${empId}`,
        method: "get",
      });
      console.log(res.data);
      setDefaultValues(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEmplopyeeDetails();
  }, []);

  const fields = Object.keys(defaultValues)

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>View Details</Button>
      </DialogTrigger>
      <DialogContent className={`sm:max-w-[420px] h-[auto] overflow-auto`}>
        <DialogHeader>
          <DialogTitle>Employee Details</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        {defaultValues.name && <p className="text-slate-700"><span className="font-medium text-slate-900">Name</span> {defaultValues.name}</p>}
        {defaultValues.email && <p className="text-slate-700"><span className="font-medium text-slate-900">Email</span> {defaultValues.email}</p>}
        {defaultValues.employeeId && <p className="text-slate-700"><span className="font-medium text-slate-900">Employee ID</span> {defaultValues.employeeId}</p>}
        {defaultValues.employeeType && <p className="text-slate-700"><span className="font-medium text-slate-900">Employee Type</span> {defaultValues.employeeType}</p>}
        {defaultValues.experience && <p className="text-slate-700"><span className="font-medium text-slate-900">Experience</span> {defaultValues.experience}</p>}
        {defaultValues.fatherName && <p className="text-slate-700"><span className="font-medium text-slate-900">Father Name</span> {defaultValues.fatherName}</p>}
        {defaultValues.gender && <p className="text-slate-700"><span className="font-medium text-slate-900">Gender</span> {defaultValues.gender}</p>}
        {defaultValues.joiningDate && <p className="text-slate-700"><span className="font-medium text-slate-900">Joining Date</span> {defaultValues.joiningDate}</p>}
        {defaultValues.mobileNumber && <p className="text-slate-700"><span className="font-medium text-slate-900">Mobile Number</span> {defaultValues.mobileNumber}</p>}
        {defaultValues.monthlySalary && <p className="text-slate-700"><span className="font-medium text-slate-900">Monthly Salary</span> {defaultValues.monthlySalary}</p>}
        {defaultValues.religion && <p className="text-slate-700"><span className="font-medium text-slate-900">Religion</span> {defaultValues.religion}</p>}
        {defaultValues.status && <p className="text-slate-700"><span className="font-medium text-slate-900">Status</span> {defaultValues.status}</p>}
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default DialogBoxWithAPI;
