import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import InputField from "@/components/InputField";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import Loading from "@/components/Loading";
import { SERVER_LINK } from "@/constant";
import axios from "axios";
import AlertBox from "@/components/AlertBox";
import { useDispatch } from "react-redux";
import { setPhoneNumber } from "@/store/otpSlice";
import { useNavigate } from "react-router-dom";
import Heading4 from "@/components/Heading4";
import Paragraph from "@/components/Paragraph";
import Heading from "@/components/Heading";

function PhoneNumberVerify() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function sendOtp(data) {
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/otp/request-otp`,
        method: "post",
        data: data,
      });
      console.log(res.data);
      if (res.status >= 200 || res.status < 400) {
        dispatch(setPhoneNumber({ phoneNumber: data.phoneNumber }));
        setOpen(true);
        setSuccess(true);
        setErrorMessage("OTP has been sent");
        navigate("/verify-signup-otp");
      }
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage(error?.response?.data?.error?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-full flex flex-col justify-center items-center gap-5 mt-[2rem]">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <img src={logo} className="w-[10rem]" alt="" />
      <section className="text-center mt-[8rem] w-[35rem]">
        <Heading>Phone Verification</Heading>
        <Paragraph className={"text-[15px] text-slate-500"}>
          Please enter your valid phone number along with country code to verify
          your phone number.
        </Paragraph>
      </section>
      <form
        className="w-full flex flex-col justify-center items-center gap-5"
        onSubmit={handleSubmit(sendOtp)}
      >
        <InputField
          label={"Mobile Number"}
          placeholder={"E.g, 9587456983"}
          type={"text"}
          {...register("phoneNumber", {
            pattern: {
              value: /^\+\d+$/,
              message: "Please include country code",
            },
          })}
        />
        {errors?.phoneNumber && (
          <p className="text-red-500 text-[14px]">
            {errors?.phoneNumber?.message}
          </p>
        )}
        {loading === true ? (
          <Loading color={"bg-sky-500"} />
        ) : (
          <Button>Send OTP</Button>
        )}
      </form>
    </div>
  );
}

export default PhoneNumberVerify;
