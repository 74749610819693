import React from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

function TableBox({ caption, fieldArray = [], dataArray = [], footer }) {

    //checking all fields in each dataArray element
    let fields = []

    if(dataArray[0] != undefined) {
      fields = Object.keys(dataArray[0])
    }

  return (
    <Table>
      {caption && <TableCaption>{caption}</TableCaption>}
      <TableHeader>
        <TableRow>
          {fieldArray.map((e,i) => <TableHead className="text-center" key={i}>{e}</TableHead>)}
        </TableRow>
      </TableHeader>
      <TableBody>
        {dataArray.map((e,i) => (
            <TableRow key={i}>
                {fields && fields.map((f,i) => <TableCell className="text-center" key={i}>{e[f]}</TableCell>)}
            </TableRow>
        ))}
      </TableBody>
      {footer && <TableFooter>{footer}</TableFooter>}
    </Table>
  );
}

export default TableBox;
