import React from "react";

function Paragraph({className, children}) {
  return (
    <p className={`${className} leading-6`}>
      {children}
    </p>
  );
}

export default Paragraph;
