import React, { useState } from "react";
import ColorCard from "@/components/ColorCard";
import { Calendar } from "@/components/ui/calendar";
import PieChart from "@/components/PieChart";
import Heading from "@/components/Heading";
import Heading2 from "@/components/Heading2";
import birthdayImg from "@/assets/birthday.jpg";

function Dashboard() {
  const [presentStudents, setPresentStudents] = useState(80);
  const [presentEmployees, setPresentEmployees] = useState(60);

  const studentData = [presentStudents, 100 - presentStudents];

  const employeeData = [presentEmployees, 100 - presentEmployees];

  const param = ["present", "absent"];

  return (
    <div className="w-full px-10 py-5">
      <Heading>Dashboard</Heading>
      <div className="w-full flex flex-col justify-center items-center mt-[5rem]">
        <div className="w-full flex flex-wrap gap-5 justify-around">
          <ColorCard
            title={"Students"}
            thisMonth={"10"}
            total={"200"}
            className={"bg-[#1D5D9B] text-white"}
          />
          <ColorCard
            title={"Employees"}
            thisMonth={"10"}
            total={"200"}
            className={"bg-[#75C2F6] text-white"}
          />
          <ColorCard
            title={"Revenue"}
            thisMonth={"10"}
            total={"200"}
            className={"bg-[#00A9FF] text-white"}
          />
          <ColorCard
            title={"Fees Collected"}
            thisMonth={"10"}
            total={"200"}
            className={"bg-[#008DDA] text-white"}
          />
        </div>
      </div>
      <div className="w-full flex flex-wrap justify-around items-center mt-[5rem] gap-5">
        <div className="border gap-5 flex px-5 rounded-lg text-center py-5">
          <div className="border-r-[2px] flex flex-col justify-center items-center">
            <Heading2>Student Attendence</Heading2>
            <section className="text-[12px] flex items-center gap-4 border-[1px] px-2 py-1 rounded-md">
              <div className="flex items-center gap-2">
                <section className="w-[7px] h-[7px] bg-[#1D5D9B] rounded-full"></section>{" "}
                means <span className="text-green-500 font-medium">present</span>
              </div>
              <div className="flex items-center gap-2">
                <section className="w-[7px] h-[7px] bg-[#75C2F6] rounded-full"></section>{" "}
                means <span className="text-red-500 font-medium">absent</span>
              </div>
            </section>
            <PieChart dataset={studentData} label={"students"} param={param} />
          </div>
          <div className="flex flex-col justify-center items-center">
            <Heading2>Employee Attendence</Heading2>
            <section className="text-[12px] flex items-center gap-4 border-[1px] px-2 py-1 rounded-md">
              <div className="flex items-center gap-2">
                <section className="w-[7px] h-[7px] bg-[#1D5D9B] rounded-full"></section>{" "}
                means <span className="text-green-500 font-medium">present</span>
              </div>
              <div className="flex items-center gap-2">
                <section className="w-[7px] h-[7px] bg-[#75C2F6] rounded-full"></section>{" "}
                means <span className="text-red-500 font-medium">absent</span>
              </div>
            </section>
            <PieChart dataset={employeeData} label={"students"} param={param} />
          </div>
        </div>
        <div className="flex gap-10 text-center">
          <div
            className="border h-[25rem] w-[20rem] p-5 flex flex-col justify-center items-center object-fill bg-cover bg-center rounded-lg text-white"
            style={{ backgroundImage: `url(${birthdayImg})` }}
          >
            <Heading2 className={"text-[30px] leading-[4rem]"}>
              Happy Birthday
            </Heading2>
            <p className="text-[50px] font-bold">Anuj</p>
          </div>
          <Calendar
            mode="single"
            className="rounded-md border h-[25rem] w-[20rem] flex justify-center items-center"
          />
        </div>
      </div>
    </div>
  );
}

//<PieChart dataset={employeeData} label={"students"} param={param} />

export default Dashboard;
