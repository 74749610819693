import DialogBox from "@/components/DialogBox";
import Heading from "@/components/Heading";
import InputField from "@/components/InputField";
import Loading from "@/components/Loading";
import TableBox from "@/components/TableBox";
import { Button } from "@/components/ui/button";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { SERVER_LINK } from "@/constant";
import { useSelector } from "react-redux";
import AlertBox from "@/components/AlertBox";

function ChartOfAccount() {
  const {
    register: registerCreateChart,
    handleSubmit: handleCreateChartSubmit,
  } = useForm();
  const { register: registerIncome, handleSubmit: handleIncomeSubmit } =
    useForm();
  const { register: registerExpense, handleSubmit: handleExpenseSubmit } =
    useForm();

  const [loading, setLoading] = useState(false);
  const institutionId = useSelector(
    (state) => state.auth?.authInfo?.institutionId
  );
  const [defaultData, setDefaultData] = useState([]);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function addExpense(data, type, headName) {
    data = { ...data, type, description: headName, institutionId };
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/chartofaccounts/addAccountStatement`,
        method: "post",
        data: data,
      });
      setOpen(true);
      setSuccess(true);
      setErrorMessage("Account statement has been added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Account statement add failed");
    } finally {
      setLoading(false);
    }
  }

  async function addIncome(data, type, headName) {
    data = { ...data, type, description: headName, institutionId };
    //console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/chartofaccounts/addAccountStatement`,
        method: "post",
        data: data,
      });
      setOpen(true);
      setSuccess(true);
      setErrorMessage("Account statement has been added");
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Account statement add failed");
    } finally {
      setLoading(false);
    }
  }

  async function createChart(data) {
    data = { ...data, institutionId };
    console.log(data);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/chartofaccounts/createchartofaccount`,
        method: "post",
        data: data,
      });
      setOpen(true);
      setSuccess(true);
      setErrorMessage("Account has been added");
      setReload((prev) => !prev);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Account add failed");
    } finally {
      setLoading(false);
    }
  }

  async function getAccountData() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/chartofaccounts/getchartofaccount?institutionId=${institutionId}`,
        method: "get",
      });
      setDefaultData(res.data);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Account data fetch failed");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAccountData();
  }, [reload]);

  const fieldArray = [
    <p className="text-left text-slate-500 font-medium">Head</p>,
    "Type",
    "Income",
    "Expense",
    <p className="text-right text-slate-500 font-medium">Delete</p>,
  ];

  async function deleteAccount(headName) {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/chartofaccounts/deletechartofaccount`,
        method: "post",
        data: {
          headName,
          institutionId,
        },
      });
      setOpen(true);
      setSuccess(true);
      setErrorMessage("Account has been deleted");
      setReload((prev) => !prev);
    } catch (error) {
      setOpen(true);
      setSuccess(false);
      setErrorMessage("Account delete failed");
    } finally {
      setLoading(false);
    }
  }

  const valueArray = defaultData.map((e) => {
    //console.log(e);
    const value = {
      head: <p className="text-left">{e?.name}</p>,
      type: e.type,
      //Income
      income: (
        <DialogBox
          title={"Add Income"}
          contentTitle={`Add Income To ${e?.name}`}
        >
          <form
            onSubmit={handleIncomeSubmit((data) =>
              addIncome(data, e?.type, e?.name)
            )}
            className="space-y-5"
          >
            <InputField label="Date" type="date" {...registerIncome("date")} />
            <InputField
              label="Amount"
              type="text"
              {...registerIncome("amount")}
            />
            <Button>Submit</Button>
          </form>
        </DialogBox>
      ),
      //Expense
      expense: (
        <DialogBox
          title={"Add Expense"}
          contentTitle={`Add Expense To ${e?.name}`}
        >
          <form
            onSubmit={handleExpenseSubmit((data) => addExpense(data, e?.type, e?.name))}
            className="space-y-5"
          >
            <InputField label="Date" type="date" {...registerExpense("date")} />
            <InputField
              label="Amount"
              type="text"
              {...registerExpense("amount")}
            />
            <Button>Submit</Button>
          </form>
        </DialogBox>
      ),
      delete: (
        <div className="flex w-full justify-end">
          <Button variant="destructive" onClick={() => deleteAccount(e?.name)}>
            Delete
          </Button>
        </div>
      ),
    };

    return value;
  });

  if (loading) {
    return (
      <div className="w-full">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  return (
    <div className="w-full px-10 py-5">
      {success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      {!success && (
        <AlertBox
          open={open}
          setOpen={setOpen}
          success={success}
          title={errorMessage}
        />
      )}
      <Heading>Accounts</Heading>
      <div className="w-full flex flex-col justify-end items-end gap-5">
        <DialogBox title={"Add Chart of Account"}>
          <form
            onSubmit={handleCreateChartSubmit(createChart)}
            className="space-y-5 mt-5"
          >
            <InputField
              label="Name of Head"
              {...registerCreateChart("headName")}
            />
            <select
              {...registerCreateChart("type")}
              className="w-full h-10 px-3 rounded-md border border-slate-200 bg-white"
            >
              <option className="text-slate-500">Select A Type</option>
              <option value="INCOME">INCOME</option>
              <option value="EXPENSE">EXPENSE</option>
            </select>
            <Button>Save</Button>
          </form>
        </DialogBox>
        <div className="border-[1px] w-full p-10 rounded-lg">
          <TableBox fieldArray={fieldArray} dataArray={valueArray} />
        </div>
      </div>
    </div>
  );
}

export default ChartOfAccount;
