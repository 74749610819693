import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "./ui/button";
import UpdateDialogBox from "./UpdateDialogBox";
import Loading from "./Loading";
import axios from "axios";
import { SERVER_LINK } from "@/constant";

function detailsDialogBox(values) {
  //console.log(values);
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Details</Button>
      </DialogTrigger>
      <DialogContent className={`sm:max-w-[420px] h-[auto] overflow-auto`}>
        <DialogHeader>
          <DialogTitle>{values.className}</DialogTitle>
          <DialogDescription>Here is the class details</DialogDescription>
        </DialogHeader>
        <section className="text-[14px] text-slate-500">
          <span className="text-slate-950">Class Name</span>
          <p>{values.className}</p>
        </section>
        <section className="text-[14px] text-slate-500">
          <span className="text-slate-950">Class Teacher</span>
          <p>{values.classTeacher}</p>
        </section>
        <section className="text-[13px] space-x-2">
          {values.subjects.map((s) => (
            <span className="text-sky-900 font-medium bg-sky-200 px-3 py-[8px] rounded-xl">
              {s.name.toUpperCase()}
            </span>
          ))}
        </section>
      </DialogContent>
    </Dialog>
  );
}


function DataTable({ defaultValues, teachers, setReload }) {
  const [loading, setLoading] = useState(false);

  async function deleteClasses(classId) {
    console.log(classId);
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/class/deleteclass`,
        method: "post",
        data: {classId: classId},
      });
      //console.log(res);
      if (res.status >= 200 || res.status < 400) {
        setReload((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Table>
      <TableCaption>A list of your all classes.</TableCaption>
      <TableHeader>
        <TableRow className="text-center">
          <TableHead className="w-[100px] text-center">Class ID</TableHead>
          <TableHead className="text-center">Class Name</TableHead>
          <TableHead className="text-center">Class Teacher</TableHead>
          <TableHead className="text-center">Monthly Tuition Fee</TableHead>
          <TableHead className="text-center">Details</TableHead>
          <TableHead className="text-center">Update</TableHead>
          <TableHead className="text-center">Delete</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody className="text-center">
        {defaultValues.map((e, i) => (
          <TableRow key={i}>
            <TableCell className="font-medium">{e.classID}</TableCell>
            <TableCell>{e.className}</TableCell>
            <TableCell>{e.classTeacher}</TableCell>
            <TableCell>{e.monthlyTuitionFee}</TableCell>
            <TableCell>{detailsDialogBox(e)}</TableCell>
            <TableCell><UpdateDialogBox setReload={setReload} values={e} teachers={teachers} /></TableCell>
            <TableCell className="text-center">
              <Button
                variant="destructive"
                onClick={() => deleteClasses(e.classID)}
              >
                {loading ? <Loading color={"bg-white"} /> : "Delete"}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter></TableFooter>
    </Table>
  );
}

export default DataTable;
