import Heading from "@/components/Heading";
import React, { useState, useEffect } from "react";
import PersonCard from "@/components/PersonCard";
import Loading from "@/components/Loading";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SERVER_LINK } from "@/constant";
import { deleteUpdateData } from "@/store/updateSlice";

function AllEmployees() {
  const [loading, setLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState([]);
  const institutionId = useSelector(
    (state) => state.auth?.authInfo?.institutionId
  );
  const dispatch = useDispatch()
  const [reload, setReload] = useState(false)

  dispatch(deleteUpdateData())

  async function getEmplopyeeDetails() {
    try {
      setLoading(true);
      const res = await axios({
        url: `${SERVER_LINK}/employee/getemployees?institutionId=${institutionId}`,
        method: "get",
      });
      console.log(res.data);
      setDefaultValues(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEmplopyeeDetails();
  }, [reload]);

  if (loading) {
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center">
        <Loading color={"bg-sky-500"} />
      </div>
    );
  }

  return (
    <div className="w-full px-10 py-5">
      <Heading>All Employees</Heading>
      <div className="w-full flex gap-5 justify-center items-end flex-wrap mt-10">
        {defaultValues.map((e) => (
          <PersonCard data={e} setReload={setReload} />
        ))}
      </div>
    </div>
  );
}

export default AllEmployees;
