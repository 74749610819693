import React from "react";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";

function Container({ children }) {

  const authStatus = useSelector(state => state.auth.status)

  console.log(authStatus);

  return (
    <div className="flex">
      <Navbar />
      { authStatus === true ? <div className="w-full ml-[10rem] lg:ml-[18rem]">{children}</div> : <div className="w-full">{children}</div>}
    </div>
  );
}

export default Container;
